import { axiosAuthInstance } from "./axiosInstance";

type ISignInPayload = {
    email: string;
    password: string;
}

export const signIn = (payload: ISignInPayload) => {
    return axiosAuthInstance({
      method: "POST",
      url: `loginmember?username=${payload.email}&password=${payload.password}&sc_apikey=4B0DBFD0-D7B1-4FB4-8B72-D48EFEB66FDE`
    });
  };