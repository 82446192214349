import React, { ReactElement } from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { CSVLink } from "react-csv";
import { Loading } from "../Loading";
import BootcampSelector from "../BootcampSelector";
import { InputRowWrapper } from "../InputRow";
import { FetchData } from "../../queries/fetcher";
import { getSurveyData } from "../../queries/queries";
import {
  SurveyData,
  Survey,
  SurveyResultsListV2State,
  SurveyResponses,
} from "../../interfaces/surveys";
import SurveyV2 from "../SurveyV2";
import { Card } from "react-bootstrap";
import { CSVHeader, CSVRow } from "../../interfaces/csv";
import { fetchSurveyQuestionData } from "../../utils/SurveyUtil";

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 5rem;
`;

interface SurveysV2Props {
  pmoId?: string;
  bootcampId?: string;
  emailAddress?: string;
}

export const SurveysV2: React.FC<SurveysV2Props> = ({
  pmoId,
  bootcampId,
  emailAddress,
}) => {
  const [message, setMessage] = useState<string>("");
  const [surveyElements, setSurveyElements] = useState<ReactElement[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBootcamp, setSelectedBootcamp] = useState<string>(
    bootcampId || ""
  );
  const [bootcampsIds, setBootcampsIds] = useState<
    {
      id: string;
      title: string;
    }[]
  >([]);

  const [surveyQuestions, setSurveyQuestions] = useState<SurveyData[] | null>(
    null
  );
  const [surveyResults, setSurveyResults] = useState<SurveyResultsListV2State>(
    {}
  );

  const handleLoadData = () => {
    setLoading(true);
    const fetchDataForId = async (id: string): Promise<SurveyData | null> => {
      try {
        const data = await FetchData<SurveyData>(getSurveyData(id));
        return data;
      } catch (error: any) {
        if (error.response?.status === 404) {
          setMessage("Survey responses not found");
        } else {
          setMessage(error.message);
        }
        return null;
      }
    };

    if (selectedBootcamp) {
      fetchDataForId(selectedBootcamp).then((data) => {
        setSurveyQuestions(data ? [data] : []);
      });
    } else {
      const ids = bootcampsIds.map((bootcamp) => bootcamp.id);
      const fetchDataPromises = ids.map((id) => fetchDataForId(id));

      Promise.all(fetchDataPromises).then((dataArray) => {
        const filteredDataArray = dataArray.filter((data) => data !== null);
        setSurveyQuestions(filteredDataArray);
      });
    }

    const baseUrl = process.env.REACT_APP_NOT_SECRET_SERVICE_URL;
    const encodedPmoId = pmoId ? encodeURIComponent(pmoId) : "";
    const encodedEmailAddress = emailAddress
      ? encodeURIComponent(emailAddress)
      : "";

    const url = `${baseUrl}/webapi/adminreporting/getsurveyresponsesv2?bootcampId=${selectedBootcamp}&pmoId=${encodedPmoId}&emailAddress=${encodedEmailAddress}`;

    axios({
      method: "Get",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage("");
          setSurveyResults(response.data);
        } else {
          setMessage(response.statusText);
        }
      })
      .catch((error) => {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          setMessage("Survey responses not found");
          setSurveyResults({});
        } else {
          setMessage(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
        setSurveyElements([]);
      });
  };

  const handleBootcampChange = (bootcampId: string) => {
    setSelectedBootcamp(bootcampId);
  };

  const generateCSVData = async (
    survey: Survey | null,
    surveyResponses: SurveyResponses[]
  ): Promise<{ headers: CSVHeader[]; rows: any[] }> => {
    let headers: CSVHeader[] = [];
    let rows: CSVRow[] = [];

    if (survey) {
      const questionDetailsPromises = survey.surveyQuestion.results.map(
        (question) =>
          fetchSurveyQuestionData(
            question.path,
            question.id,
            question.question?.value ?? ""
          ).then((questionDetail) => {
            // Combine the original question with its details
            return { question, questionDetail };
          })
      );

      const questionDetailsWithOriginal = await Promise.all(
        questionDetailsPromises
      );

      const userDetails = [
        { label: "PMOId", key: "PMOId" },
        { label: "FirstName", key: "FirstName" },
        { label: "LastName", key: "LastName" },
        { label: "EmailAddress", key: "EmailAddress" },
        { label: "Submission Date", key: "SubmitedDate" },
        { label: "Marketing Consent", key: "GaveMarketingConsent" },
      ];

      userDetails.forEach((config) => {
        headers.push(config);
      });

      questionDetailsWithOriginal.forEach(({ question, questionDetail }) => {
        // Now you have access to both question and questionDetail
        // Check if the questionDetail is a parent and process subquestions

        if (questionDetail.isParent) {
          // Table
          questionDetail.questions?.item?.table?.results[0]?.tableRows?.results.forEach(
            (subQuestion) => {
              headers.push({
                label:
                  question.question.value + " - " + subQuestion.rowText?.value,
                key: subQuestion.id,
              });
            }
          );
          questionDetail.questions?.item?.table?.results.forEach(
            (subQuestion) => {
              headers.push({
                label: question.question.value + " - Other",
                key: subQuestion.includeOther.id,
              });
            }
          );
          // Star Ratings
          questionDetail.questions?.item?.StarRatings?.results.forEach(
            (subQuestion) => {
              headers.push({
                label:
                  question.question.value + " - " + subQuestion.question?.value,
                key: subQuestion.id,
              });
            }
          );
        } else {
          // If it's not a parent, just use the main question
          headers.push({
            label: question.question.value,
            key: question.id,
          });
        }
      });

      // Now you can process the surveyResponses to generate rows
      surveyResponses.forEach((response) => {
        response.Items.forEach((item) => {
          let row: CSVRow = {};
          headers.forEach((header) => {
            const answerObj = item.SurveyResultsData.find(
              (result) =>
                result.QuestionId.normalizeGuid() === header.key.normalizeGuid()
            );
            row[header.key] = answerObj ? answerObj.Answer : "No response";

            userDetails.forEach((detail) => {
              if (header.key === detail.key) {
                row[header.key] = (item as { [key: string]: any })[detail.key];
              }
            });
          });
          rows.push(row);
        });
      });
    }

    return { headers, rows };
  };

  useEffect(() => {
    if (pmoId || emailAddress) {
      handleLoadData();
    }
  }, [pmoId, emailAddress]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getallbootcamps`
      )
      .then((response) => {
        const bootcampsData = response.data;
        const bootcamps = Object.keys(bootcampsData).map((key) => ({
          id: key,
          title: bootcampsData[key],
        }));
        setBootcampsIds(bootcamps);
      })
      .catch((error) => {
        console.log("Error fetching bootcamps:", error);
      });
  }, []);

  const getSurveyQuestionsForBootcamp = (
    surveyDataArray: SurveyData[] | null,
    bootcampId: string
  ): Survey[] => {
    if (!surveyDataArray) return [];

    const surveyDataForBootcamp = surveyDataArray.find(
      (surveyData) =>
        surveyData?.item?.id.normalizeGuid() === bootcampId.normalizeGuid()
    );

    if (!surveyDataForBootcamp) return [];

    return surveyDataForBootcamp.item.data.results.flatMap(
      (result) => result.surveyItem.results as Survey[]
    );
  };

  const formattedDate = new Date().toISOString().replace(/[-:.TZ]/g, "");

  useEffect(() => {
    const fetchData = async () => {
      const promises = Object.entries(surveyResults).map(
        async ([bootcampId, surveyData]) => {
          const foundBootcamp = bootcampsIds.find(
            (bootcamp) => bootcamp.id === bootcampId
          );
          const surveysForBootcamp = getSurveyQuestionsForBootcamp(
            surveyQuestions,
            bootcampId
          );

          const filteredSurveyResponses = surveyData?.Items?.filter(
            (surveyResponse) =>
              surveyResponse.SurveyId.normalizeGuid() ===
              surveysForBootcamp[0]?.id.normalizeGuid()
          );

          if (
            (emailAddress || pmoId) &&
            filteredSurveyResponses.length > 0 &&
            filteredSurveyResponses[0].Items.length === 0
          )
            return <p>No surveys submitted</p>;

          const filename = `Survey-Report-${foundBootcamp?.title}-${formattedDate}.csv`;
          const csvContent = await generateCSVData(
            surveysForBootcamp[0],
            filteredSurveyResponses
          );

          return (
            <Card key={bootcampId}>
              <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
              <h2>{foundBootcamp?.title}</h2>
                {surveyQuestions && (
                  <CSVLink
                    data={csvContent.rows}
                    headers={csvContent.headers}
                    filename={filename}
                    className="cta cta--small cta--navy"
                    style={{ borderRadius: "4px" }}
                  >
                    <span>Export CSV</span>
                  </CSVLink>
                )}

                </div>

              </Card.Header>
              <Card.Body>
                {filteredSurveyResponses?.map((surveyResponses, index) => (
                  <SurveyV2
                    surveyResponses={surveyResponses}
                    surveys={surveysForBootcamp}
                    key={bootcampId + index}
                  />
                ))}
              </Card.Body>
            </Card>
          );
        }
      );

      const newSurveyElements = await Promise.all(promises);
      setSurveyElements(newSurveyElements);
    };
    fetchData();
  }, [surveyResults, surveyQuestions]);

  return (
    <>
      {!pmoId && !emailAddress && (
        <>
          <h1>Surveys</h1>
          {message && <p>{message}</p>}
          <InputRowWrapper>
            Bootcamp:
            <BootcampSelector onSelectBootcamp={handleBootcampChange} />
            <button
              onClick={handleLoadData}
              className="cta cta--small cta--navy"
            >
              <span>Load Data</span>
            </button>
          </InputRowWrapper>
        </>
      )}

      {loading ? (
        <LoadingWrapper>
          <Loading />
          <br />
          <p>Loading...</p>
        </LoadingWrapper>
      ) : (
        <>{surveyElements}</>
      )}
    </>
  );
};
