import styled from "styled-components";
import { QuestionResult, Question } from "../interfaces/memberSummary";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import "../extensions/StringExtensions";

export interface SubmittedQuestionsProps {
  questionResults: QuestionResult[];
}

const SubmittedQuestions: React.FC<SubmittedQuestionsProps> = ({
  questionResults,
}) => {
  if (questionResults.length === 0) return <p>No questions submitted</p>;

  return (
    <>
      {questionResults.map((questionResult) => (
        <SingleQuestion key={questionResult.SubmitQuestionData.Id} {...questionResult} />
      ))}
    </>
  );
};

const SingleQuestionWrapper = styled.div`
  margin: 2rem 0;
  background-color: var(--bg-light);
  padding: 1rem;
  border: 1px solid var(--pm-blue);
`;

const QuestionLink = styled.a`
  color: var(--pm-blue);  

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
`;

export const getQuestionId = (courseId: string): string => {
  return "question-" + courseId.removeNonAlphaNumeric();
};

export const SingleQuestion: React.FC<QuestionResult> = (questionResult) => {
  const navigate = useNavigate()
  return (
    <SingleQuestionWrapper id={getQuestionId(questionResult.SubmitQuestionData.CourseId)}>
      <p>
        <strong>
          <span dangerouslySetInnerHTML={{ __html: questionResult.SubmitQuestionData.CourseTitle }} />
        </strong>{" "}
        -{" "}
        <span>
          Submitted on {Moment(questionResult.SubmitQuestionData.SubmissionDate).format("MM/DD/yyyy")} by <QuestionLink onClick={() => navigate(`/users/${questionResult.PMOId}`)}>{questionResult.FirstName} {questionResult.LastName} ({questionResult.EmailAddress})</QuestionLink>
        </span>
      </p>
      <p>{questionResult.SubmitQuestionData.QuestionText}</p>
    </SingleQuestionWrapper>
  );
};

export default SubmittedQuestions;
