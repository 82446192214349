import React, { useEffect, useState } from "react";
import axios from "axios";

interface Bootcamp {
  id: string;
  title: string;
}

interface BootcampDropdownProps {
  onSelectBootcamp: (bootcampId: string) => void;
  className?: string;
  isLoading?: boolean
}

const BootcampDropdown: React.FC<BootcampDropdownProps> = ({
  onSelectBootcamp,
  className,
  isLoading
}) => {
  const [bootcamps, setBootcamps] = useState<Bootcamp[]>([]);
  const [selectedBootcamp, setSelectedBootcamp] = useState<string>("");

  useEffect(() => {
    fetchBootcamps();
  }, []);

  const fetchBootcamps = () => {
    axios
      .get(
        `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getallbootcamps`
      )
      .then((response) => {
        const bootcampsData = response.data;
        const bootcamps = Object.keys(bootcampsData).map((key) => ({
          id: key,
          title: bootcampsData[key],
        }));
        setBootcamps(bootcamps);
      })
      .catch((error) => {
        console.log("Error fetching bootcamps:", error);
      });
  };

  const handleBootcampChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const bootcampId = e.target.value;
    setSelectedBootcamp(bootcampId);
    onSelectBootcamp(bootcampId);
  };
  var completeName = `form-field ${className}`;

  return (
    
    <select
      disabled={isLoading}
      value={selectedBootcamp}
      onChange={handleBootcampChange}
      className={completeName}
      title="Bootcamp Selector"
    >
      <option value="">All Bootcamps</option>
      {bootcamps.map((bootcamp) => (
        <option key={bootcamp.id} value={bootcamp.id}>
          {bootcamp.title}
        </option>
      ))}
    </select>
  );
};

export default BootcampDropdown;