import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import { Loading } from "../components/Loading";
import { BootcampSummary } from "../interfaces/bootcampSummary";
import {
  GetCourseProgressIcon,
  RoundToTwo,
  SecondsToMinutesAndSeconds,
} from "../utils/ProgressUtil";
import { CourseProgressSummary } from "../interfaces/courseMemberSummary";

export interface CourseUsersProps {
  bootcampSummary: BootcampSummary;
  courseId: string;
}

const cursorPointer = {
  cursor: "pointer",
};

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 5rem;
`;

const ProgressTable = styled.table`
  width: 100%;

  td,
  th {
    padding: 0.5rem 1rem;
  }

  thead,
  tfoot {
    background-color: #003678;
    color: white;
  }
`;

const StandardRow = styled.tr`
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const CourseUsers: React.FC<CourseUsersProps> = ({
  bootcampSummary,
  courseId,
}) => {
  const [message, setMessage] = useState<string>("");
  const [courseProgressSummaries, setData] = useState<
    CourseProgressSummary[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLoadData = () => {
    const url = `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getmembersummariesbycourseid?&courseId=${courseId}`;
    setLoading(true);

    axios({
      method: "Get",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage("");
          setData(response.data);
        } else {
          setMessage(response.statusText);
        }
      })
      .catch((error) => {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          setMessage("Bootcamp not found");
          setData(null);
        } else {
          setMessage(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (courseId) {
      handleLoadData();
    }
  }, [courseId]);

  let foundTrack = null;
  let foundModule = null;
  let foundCourse = null;

  for (const track of bootcampSummary.tracks) {
    for (const module of track.modules) {
      const course = module.courses.find((course) => course.id === courseId);
      if (course) {
        foundTrack = track;
        foundModule = module;
        foundCourse = course;
        break;
      }
    }
    if (foundCourse) {
      break;
    }
  }

  const headers = [
    { label: "Bootcamp ID", key: "BootcampId" },
    { label: "Course ID", key: "CourseId" },
    { label: "User Name", key: "UserName" },
    { label: "PMO ID", key: "PMOId" },
    { label: "Completed", key: "Completed" },
    { label: "Time Spend", key: "TimeSpend" },
    { label: "Length", key: "Length" },
    { label: "Progress", key: "Progress" },
    { label: "Credited Time", key: "CreditedTime" },
    { label: "Questions", key: "SubmittedQuestions" },
  ];

  const formattedDate = Moment().format("YYYYMMDD-HHmmss");
  const courseName = foundCourse?.course_title;
  const filename = `Course-Progress-Report-${courseName}-${formattedDate}.csv`;

  const csvData = courseProgressSummaries?.map((course) => ({
    BootcampId: bootcampSummary.id,
    CourseId: course.member_progress_course_details.course_id,
    UserName: course.base_member_summary.EmailAddress,
    PMOId: course.base_member_summary.pmoId,
    Completed: course.member_progress_course_details.completed,
    TimeSpend: RoundToTwo(course.member_progress_course_details.time / 60),
    Length: RoundToTwo(course.member_progress_course_details.length / 60),
    Progress: RoundToTwo(course.member_progress_course_details.progress / 60),
    CreditedTime: RoundToTwo(
      course.member_progress_course_details.credited_time / 60
    ),
    SubmittedQuestions:
      course.member_progress_course_details.submitted_questions,
  }));

  return (
    <>
      {message && <p>{message}</p>}
      {loading ? (
        <LoadingWrapper>
          <Loading />
          <br />
          <p>Loading...</p>
        </LoadingWrapper>
      ) : (
        <>
          <div className="text-right mb-3">
            {csvData && csvData.length > 0 && (
              <CSVLink
                data={csvData}
                headers={headers}
                filename={filename}
                className="cta cta--small cta--navy"
                style={{ borderRadius: "4px", margin: "0 1rem" }}
              >
                <span>Export CSV</span>
              </CSVLink>
            )}
          </div>

          <Card>
            <Card.Header>
              <h2
                onClick={() => navigate(`/bootcamps/${bootcampSummary.id}`)}
                style={cursorPointer}
              >
                {bootcampSummary.activity_title}
              </h2>
              <h3>{foundTrack && foundTrack.display_name}</h3>
              <h3>{foundModule && foundModule.display_name}</h3>
              {foundCourse && (
                <h3
                  dangerouslySetInnerHTML={{
                    __html: foundCourse.course_title,
                  }}
                ></h3>
              )}
              {/* <h3>{foundCourse && foundCourse.course_title}</h3> */}
            </Card.Header>
            <Card.Body>
              <ProgressTable>
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>PmoID</th>
                    <th>Completed</th>
                    <th title="time as mm:ss">Time Spent</th>
                    <th title="length as mm:ss">Length</th>
                    <th title="progress as mm:ss">Progress</th>
                    <th title="credited time as mm:ss">Credited Time</th>
                    <th>Questions</th>
                  </tr>
                </thead>
                <tbody>
                  {courseProgressSummaries?.map((courseProgressSummary, i) => (
                    <StandardRow key={i}>
                      <td
                        onClick={() =>
                          navigate(
                            `/users/${courseProgressSummary.base_member_summary.pmoId}`
                          )
                        }
                        style={cursorPointer}
                      >
                        {courseProgressSummary.base_member_summary.EmailAddress}
                      </td>
                      <td
                        onClick={() =>
                          navigate(
                            `/users/${courseProgressSummary.base_member_summary.pmoId}`
                          )
                        }
                        style={cursorPointer}
                      >
                        {courseProgressSummary.base_member_summary.pmoId}
                      </td>
                      <td>
                        {GetCourseProgressIcon(
                          courseProgressSummary.member_progress_course_details
                        )}
                      </td>
                      <td>
                        {SecondsToMinutesAndSeconds(
                          courseProgressSummary.member_progress_course_details
                            .time
                        )}
                      </td>
                      <td>
                        {SecondsToMinutesAndSeconds(
                          courseProgressSummary.member_progress_course_details
                            .length
                        )}
                      </td>
                      <td>
                        {SecondsToMinutesAndSeconds(
                          courseProgressSummary.member_progress_course_details
                            .progress
                        )}
                      </td>
                      <td>
                        {SecondsToMinutesAndSeconds(
                          courseProgressSummary.member_progress_course_details
                            .credited_time
                        )}
                      </td>
                      <td>
                        {
                          courseProgressSummary.member_progress_course_details
                            .submitted_questions
                        }
                      </td>
                    </StandardRow>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total:</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </ProgressTable>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default CourseUsers;
