import { BrowserRouter, Route, Routes} from "react-router-dom";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import { Header } from "./components/Header";
import { Questions } from "./components/pages/Questions";
import { Surveys } from "./components/pages/Surveys";
import { SurveysV2 } from "./components/pages/SurveysV2";
import { UserDetails } from "./components/pages/UserDetails";
import GlobalStyle from "./components/GlobalStyle";
import { SubscriptionList } from "./components/pages/SubscriptionList";
import { AdminTools } from "./components/pages/AdminTools";
import { Bootcamps } from "./components/pages/Bootcamps";
import { Course } from "./components/pages/Course";
import Login from "./components/pages/Login";

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <BrowserRouter>
        <Header />
        <main>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<SubscriptionList />} />
              <Route path="/questions/:pmoId" element={<Questions />} />
              <Route path="/questions" element={<Questions />} />
              <Route path="/surveys/:pmoId" element={<Surveys />} />
              <Route path="/surveys/v1" element={<Surveys />} />
              <Route path="/surveys" element={<SurveysV2 />} />
              <Route path="/users/:pmoId" element={<UserDetails />} />
              <Route path="/users" element={<UserDetails />} />
              <Route path="/admin" element={<AdminTools />} />
              <Route path="/bootcamps" element={<Bootcamps />} />
              <Route path="/bootcamps/:bootcampId/" element={<Bootcamps />} />
              <Route
                path="/bootcamps/:bootcampId/:courseId"
                element={<Course />}
              />
            </Route>
          </Routes>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
