import { FinishedIcon, InProgressIcon, NotStartedIcon } from "../components/SvgIcons/SvgIcons";
import { ProgressRecord } from "../interfaces/memberSummary";

export const RoundToTwo = (input: number) => {
    return Math.round(input * 100) / 100;
  };

export const GetCourseProgressIcon = (progress: ProgressRecord): JSX.Element => {
    if (progress) {
      if (progress.completed === true) {
        return (          
            <FinishedIcon />          
        );
      } else if (progress.start_date) {
        return (          
            <InProgressIcon />          
        );
      }
    }  
    return (      
        <NotStartedIcon />      
    );
  };

  export function SecondsToMinutesAndSeconds(totalSeconds: number) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }

  export function GetTotalTime(progressRecords: any, key: string) {
    if (!progressRecords || !progressRecords.length) {
      return "0:00";
    }

    const totalSeconds = progressRecords.reduce(
      (acc: number, obj: any) => acc + obj[key],
      0
    );
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }