import { gql } from "graphql-request";

export const getSurveyData = (datasource: string): string => gql`

{
  item(path: "${datasource}", language: "en") {
    id
    data: children(includeTemplateIDs: ["{12659C14-F9F7-4066-8C4D-99B61B4B8434}"]) {
      results {
        surveyItem: children(includeTemplateIDs: ["{B9791DA5-69BC-4D01-B402-818277F9E689}"]) {
          results {
            id
            surveyHeading: field(name: "surveyHeading") {
              value
            }
            surveyQuestion: children {
              results {
                id
                path
                question: field(name: "question") {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}

`;
export const getSurveyQuestionData = (datasource: string): string => gql`
{
  item(
    path: "${datasource}"
    language: "en"
  ) {
    StarRatings: children(
      includeTemplateIDs: "{5B11651C-DA3B-4C74-AA08-FA8BAA2BF476}"
    ) {
      results {
        id
        question: field(name: "question") {
          value
        }
        highRangeDescription: field(name: "highRangeDescription") {
          value
        }
        lowRangeDescription: field(name: "lowRangeDescription") {
          value
        }
      }
    }
    zeroToTenRatings: children(
      includeTemplateIDs: "{7DA00712-8FF3-4420-B13A-E24DC8C0EEDF}"
    ) {
      results {
        question: field(name: "question") {
          jsonValue
        }
        highRangeDescription: field(name: "highRangeDescription") {
          value
        }
        lowRangeDescription: field(name: "lowRangeDescription") {
          value
        }
      }
    }
    openText: children(
      includeTemplateIDs: "{F6F28FC6-7A2C-4CF6-86AD-BF2F230EEAEE}"
    ) {
      total
    }
    radioButtons: children(
      includeTemplateIDs: "{9230D4A9-0A3C-45C0-9A24-0B82BDEDECCB}"
    ) {
      results {
        text: field(name: "text") {
          value
        }
      }
    }
    table: children(
      includeTemplateIDs: "{1A50FBA1-F015-4A92-8BBC-5514C77FBC19}"
    ) {
      results {
        tableHeadOne: field(name: "tableHeadOne") {
          value
        }
        tableHeadTwo: field(name: "tableHeadTwo") {
          value
        }
        tableHeadThree: field(name: "tableHeadThree") {
          value
        }
        includeOther: field(name: "includeOther") {
          id
          jsonValue
        }
        tableRows: children(
          includeTemplateIDs: "{314224AC-801E-4B44-8344-06BF7A6CD4D1}"
        ) {
          results {
            id
            rowText: field(name: "text") {
              value
            }
          }
        }
      }
    }
  }
}
`;