import { useMemo } from "react";
import styled from "styled-components";
import { StarIcon } from "./SvgIcons/StarIcon";
import SurveyTable from "./SurveyTable";
import { RatingData, SurveyQuestionData } from "../interfaces/surveys";
import { FinishedIcon, NotStartedIcon } from "./SvgIcons/SvgIcons";

interface SurveyQuestionV2Props {
  isParent: boolean | null;
  questionId: string;
  questions: SurveyQuestionData;
  title: string;
  index: number;
  result: {
    Id: string;
    SubmissionId: string;
    QuestionId: string;
    BootcampId: string;
    PartyId: number;
    PmoId: string;
    Answer: string;
    GaveMarketingConsent: boolean;
    SubmitedDate: string;
  }[];
}

const SurveyQuestionV2: React.FC<SurveyQuestionV2Props> = (props) => {
  const { result, isParent, questionId, index, title, questions } = props;

  const filteredTable = useMemo(() => {
    const table = questions?.item.table.results;

    if (table && table.length > 0 && isParent) {
      const modifiedTable = table.map((tableItem) => {
        const modifiedRows = tableItem.tableRows.results.map((row) => {
          const matchingAnswer = result.find(
            (resultItem) => resultItem.QuestionId.normalizeGuid() === row.id
          );
          return {
            ...row,
            answer: matchingAnswer ? matchingAnswer.Answer : null,
          };
        });

        const matchingIncludeOther = result.find(
          (resultItem) =>
            resultItem.QuestionId.normalizeGuid() === tableItem.includeOther.id
        );

        return {
          ...tableItem,
          tableRows: {
            ...tableItem.tableRows,
            results: modifiedRows,
          },
          includeOther: {
            ...tableItem.includeOther,
            answer: matchingIncludeOther ? matchingIncludeOther.Answer : null,
          },
        };
      });

      return {
        ...questions,
        item: {
          ...questions.item,
          table: {
            ...questions.item.table,
            results: modifiedTable,
          },
        },
      };
    }

    return questions;
  }, [result, questions, isParent]);

  const filteredStarRatings = useMemo(() => {
    const star = questions?.item.StarRatings.results;

    if (star && star.length > 0 && isParent) {
      const modifiedStar = star.map((starItem) => {
        const matchingAnswer = result.find(
          (resultItem) => resultItem.QuestionId.normalizeGuid() === starItem.id
        );

        return {
          ...starItem,
          answer: matchingAnswer ? matchingAnswer.Answer : null,
        };
      });

      return {
        ...questions,
        item: {
          ...questions.item,
          StarRatings: {
            ...questions.item.StarRatings,
            results: modifiedStar,
          },
        },
      };
    }

    return questions;
  }, [result, questions, isParent]);

  return (
    <>
      <p className="mb-4 font-weight-bold">
        <span>{`${props.index + 1}.  `}</span>
        {props.title}
      </p>
      {!isParent &&
        (result.some(
          (resultItem) => resultItem.QuestionId.normalizeGuid() === questionId
        )
          ? result.map((resultItem) => {
              if (resultItem.QuestionId.normalizeGuid() === questionId) {
                return (
                  questions?.item && (
                    <SingleQuestion
                      key={index}
                      index={index}
                      answer={resultItem.Answer}
                      item={questions?.item}
                      title={title}
                    />
                  )
                );
              }
              return null;
            })
          : questions?.item && (
              <SingleQuestion
                index={index}
                item={questions?.item}
                title={title}
              />
            ))}
      {isParent &&
        filteredTable &&
        filteredTable?.item.table.results.length > 0 && (
          <SingleQuestion
            index={index}
            item={filteredTable?.item}
            title={title}
          />
        )}

      {isParent &&
        filteredStarRatings &&
        filteredStarRatings.item.StarRatings.results.length > 0 && (
          <SingleQuestion
            index={index}
            item={filteredStarRatings?.item}
            title={title}
          />
        )}
    </>
  );
};

export default SurveyQuestionV2;

interface SingleQuestionProps {
  index: number;
  answer?: string | number;
  item: {
    StarRatings: {
      results: any;
    };
    zeroToTenRatings: {
      results: RatingData[];
    };
    openText: {
      total: number;
    };
    radioButtons: {
      results: { text: { value: string } }[];
    };
    table: {
      results: any;
    };
  };
  title: string;
}

const SingleQuestion = (props: SingleQuestionProps): JSX.Element => {
  const hasOpenText = props && props?.item?.openText?.total > 0;
  const radioBtns = props?.item?.radioButtons.results || [];
  const table = props?.item?.table?.results[0] || null;

  return (
    <div className="d-flex flex-column mb-5">
      <div className="d-flex flex-column ml-0 ml-lg-3">
        {table && <SurveyTable {...table} />}
        {hasOpenText && <p>{props.answer}</p>}
        {props?.item?.StarRatings?.results.map((starRating: any, i: number) => (
          <ResponseScaleStars
            scale={starRating}
            key={"star-rating-" + i}
            answer={starRating.answer}
          />
        ))}

        {props?.item?.zeroToTenRatings?.results.map((numberRating, i) => (
          <ResponseNumberRangeSet
            scale={numberRating}
            key={"number-rating-" + i}
            answer={props.answer}
          />
        ))}

        {radioBtns &&
          radioBtns.map((question, index) => (
            <div key={index}>
              {question.text.value === props.answer ? (
                <FinishedIcon />
              ) : (
                <NotStartedIcon />
              )}
              <label className="pl-2"> {question.text.value}</label>
            </div>
          ))}
      </div>
    </div>
  );
};

export const NumberRangeSetLabel = styled.label`
  input {
    max-width: 250px;
    opacity: 0;
    pointer-events: none;
    display: none;
  }

  .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 12px 0px;
    transition: background 0.2s ease-in-out;
    border: 1px solid #d9d9d9;
  }

  .highlight {
    color: white;

    background-color: #003678;

    + .img-wrapper {
      background-color: #003678;
    }
  }

  .highlight-hover {
    border: 1px solid #003678;
  }
`;

export const LikertContainer = styled.div`
  max-width: 320px;
  margin: 20px 0;
  .likert-description {
    max-width: 100px;
  }
`;

export const LikertWrapper = styled.fieldset`
  --likert-rows: 5;
  display: inline-grid;
  grid-auto-rows: 1fr;
  gap: 1em;
  grid-template-columns: repeat(var(--likert-rows), minmax(0, 1fr));

  label {
    width: 58px;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 50px;
      justify-content: center;
    }
  }
`;

export const LikertLabel = styled.label`
  width: 58px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 50px;
    justify-content: center;
  }

  input {
    max-width: 250px;
    opacity: 0;
    pointer-events: none;
    display: none;
  }

  .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 12px 0px;
    transition: background 0.2s ease-in-out;
    border: 1px solid #d9d9d9;
  }

  .highlight {
    background-color: #d8e5f0;

    + .img-wrapper {
      background-color: #d8e5f0;
    }

    svg {
      fill: #003678;
      background-color: #d8e5f0;
    }
  }

  .highlight-hover {
    border: 1px solid #003678;
  }
`;

export const NumberRangeSetContainer = styled.div`
  margin-bottom: 20px;
  max-width: 850px;
`;

export const Container = styled.div`
  max-width: 800px;
`;

export const NumberRangeSetWrapper = styled.fieldset`
  label {
    cursor: pointer;
    flex: 0 0 9%;
    margin-right: 1%;
    box-sizing: border-box;

    @media (min-width: 375px) {
      flex: 0 0 8%;
      margin-right: 1%;
    }


    &:last-child {
      margin-right: 0;
    }
  }
`;

type ResponseRatingData = { scale: RatingData; answer?: string | number };

export const ResponseNumberRangeSet: React.FC<ResponseRatingData> = ({
  scale,
  answer,
}) => {
  return (
    <>
      <h4>{scale.question.value}</h4>
      <NumberRangeSetContainer>
        <NumberRangeSetWrapper className="d-flex justify-content-between">
          {[...Array(11)].map((star, i) => {
            return (
              <NumberRangeSetLabel key={i}>
                <span
                  className={`img-wrapper ${star}
                  ${Number(answer) >= i ? "highlight" : "standard"}
                  `}
                >
                  {i}
                </span>
              </NumberRangeSetLabel>
            );
          })}
        </NumberRangeSetWrapper>
        <div className="d-flex justify-content-between">
          <div>{scale?.lowRangeDescription.value} </div>
          <div>{scale?.highRangeDescription.value} </div>
        </div>
      </NumberRangeSetContainer>
    </>
  );
};

export const ResponseScaleStars: React.FC<ResponseRatingData> = ({
  scale,
  answer,
}) => {
  return (
    <>
      <LikertContainer>
        <h5>{scale?.question.value}</h5>
        <LikertWrapper>
          {[...Array(5)].map((star, i) => (
            <LikertLabel key={i}>
              <span
                className={`img-wrapper ${star} ${
                  Number(answer) >= i + 1 ? "highlight" : "standard"
                }`}
              >
                <StarIcon />
              </span>
            </LikertLabel>
          ))}
        </LikertWrapper>
        <div className="d-flex justify-content-between">
          <div>{scale?.lowRangeDescription.value}</div>
          <div>{scale?.highRangeDescription.value}</div>
        </div>
      </LikertContainer>
    </>
  );
};
