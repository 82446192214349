import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../services/auth';
import { saveAuthTokens } from '../../utils/useAuth';

export default function Login() {
    const [message, setMessage] = useState<string>("");
    const userNameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
  
    const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
  
      try {
        if (!userNameRef.current?.value || !passwordRef.current?.value) {
          throw new Error("Please enter username and password");
        }
  
        const { data } = await signIn({
          email: userNameRef.current?.value,
          password: passwordRef.current?.value,
        });
        
        const userData = {
          accessToken: data?.bearerToken,
        };
  
        saveAuthTokens(userData);
        navigate("/");
      } catch (error) {
        setMessage(`Unable to login`);
        console.error(error);
      }
    };
  
    return (
      <>
        {message && <p>{message}</p>}
        <div className="pl-5">
          <h1>Login</h1>
          <div>
            <label>
              <p>Username</p>
              <input type="text" ref={userNameRef} />
            </label>
          </div>
          <div>
            <label>
              <p>Password</p>
              <input type="password" ref={passwordRef} />
            </label>
          </div>
          <div>
            <button onClick={handleClick} className="cta cta--small cta--navy">
              <span>Submit</span>
            </button>
          </div>
        </div>
      </>
    );
  };