import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { InputRowWrapper } from "../InputRow";
import BootcampSelector from "../BootcampSelector";
import { Loading } from "../Loading";
import { BootcampSummary } from "../../interfaces/bootcampSummary";
import Bootcamp from "../Bootcamp";

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 5rem;
`;

export const Bootcamps: React.FC = () => {
  const [message, setMessage] = useState<string>("");
  const [bootcampSummary, setbootCampSummaryData] = useState<
    BootcampSummary[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBootcamp, setSelectedBootcamp] = useState<string>("");
  const { bootcampId } = useParams();

  const handleBootcampChange = (bootcampId: string) => {
    setSelectedBootcamp(bootcampId);
  };

  const handleLoadData = (bootcampId: string) => {
    const url = `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getmultiplebootcampdata?bootcampId=${bootcampId}`;
    setLoading(true);

    axios({
      method: "Get",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage("");
          setbootCampSummaryData(response.data);
        } else {
          setMessage(response.statusText);
        }
      })
      .catch((error) => {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          setMessage("Bootcamp not found");
          setbootCampSummaryData(null);
        } else {
          setMessage(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (bootcampId) {
      handleLoadData(bootcampId);
    }
  }, [bootcampId]);

  return (
    <>
      <h1>Bootcamps</h1>

      {message && <p>{message}</p>}
      <InputRowWrapper>
        Bootcamp:
        <BootcampSelector onSelectBootcamp={handleBootcampChange} />
        <button
          onClick={() => handleLoadData(selectedBootcamp)}
          className="cta cta--small cta--navy"
        >
          <span>Load Data</span>
        </button>
      </InputRowWrapper>
      {loading ? (
        <LoadingWrapper>
          <Loading />
          <br />
          <p>Loading...</p>
        </LoadingWrapper>
      ) : bootcampSummary ? (
        bootcampSummary.map((bootcamp: BootcampSummary, index) => (
          <Bootcamp bootcamp={bootcamp} key={index} />
        ))
      ) : null}
    </>
  );
};
