import React from "react";
import styled from "styled-components";
import Card from "react-bootstrap/Card";
import { CSVLink } from "react-csv";
import Moment from "moment";

import {
  BootcampSummary,
  BootcampSummaryCourseInteraction,
} from "../interfaces/bootcampSummary";
import { useNavigate } from "react-router-dom";
import { RoundToTwo } from "../utils/ProgressUtil";

export interface BootcampProps {
  bootcamp: BootcampSummary;
}

const ProgressTable = styled.table`
  width: 100%;

  td,
  th {
    padding: 0.5rem 1rem;
  }

  thead,
  tfoot {
    background-color: #003678;
    color: white;
  }
`;

const StandardRow = styled.tr`
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const CourseLink = styled.td`
  color: var(--pm-blue);

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
`;

const Bootcamp: React.FC<BootcampProps> = ({ bootcamp }) => {
  const navigate = useNavigate();

  const headers = [
    { label: "Bootcamp Name", key: "BootcampName"},
    { label: "Track Name", key: "TrackName" },
    { label: "Modal Name", key: "ModalName" },
    { label: "Course Name", key: "CourseName" },
    { label: "Duration", key: "Duration" },
    { label: "Avg time Watched", key: "AverageWatchTime" },
    { label: "Total Time Watched", key: "TotalWatchTime" },
    { label: "Completes", key: "Completes" },
    { label: "Unique", key: "UniqueViewers" },
    { label: "Questions Submitted", key: "QuestionsSubmitted" },
  ];

  const formattedDate = Moment().format("YYYYMMDD-HHmmss");
  const filename = `BC-${bootcamp.display_name}-${formattedDate}.csv`;

  const csvData =
    bootcamp.tracks?.flatMap((track) =>
      track.modules?.flatMap((module) =>
        module.courses?.map((course) => {
          const courseInteraction = course as BootcampSummaryCourseInteraction;
          return {
            BootcampName: bootcamp.display_name,
            TrackName: track.display_name,
            ModalName: module.display_name,
            CourseName: courseInteraction.course_title,
            Duration: RoundToTwo(courseInteraction.length / 60),
            AverageWatchTime: RoundToTwo(
              courseInteraction.average_watch_time / 60
            ),
            TotalWatchTime: RoundToTwo(
              courseInteraction.total_watch_time / 60
            ),
            Completes: courseInteraction.completes,
            UniqueViewers: courseInteraction.unique_viewers,
            QuestionsSubmitted: courseInteraction.submitted_questions,
          };
        })
      )
    ) || [];

  return (
    <Card>
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
        <h2>{bootcamp.display_name}</h2>
        <CSVLink
          data={csvData}
          headers={headers}
          filename={filename}
          className="cta cta--small cta--navy"
          style={{ borderRadius: "4px" }}
        >
          <span>Export CSV</span>
        </CSVLink>
        </div>

      </Card.Header>
      <Card.Body>
        {bootcamp.tracks.map((track) => (
          <Card key={track.id}>
            <Card.Header>
              <h4>{track.display_name}</h4>
            </Card.Header>
            <Card.Body>
              {track.modules.map((module) => (
                <Card key={module.id}>
                  <Card.Header>{module.display_name}</Card.Header>
                  <Card.Body>
                    <ProgressTable>
                      <thead>
                        <tr>
                          <th>Course Name</th>
                          <th>Duration</th>
                          <th>Avg Time Watched</th>
                          <th>Total Time Watched</th>
                          <th>Completes</th>
                          <th>Unique Viewers</th>
                          <th>Questions Submitted</th>
                        </tr>
                      </thead>
                      <tbody>
                        {module.courses.map((course) =>
                          (function () {
                            var courseInteraction =
                              course as BootcampSummaryCourseInteraction;
                            return (
                              <StandardRow key={course.id}>
                                <CourseLink
                                  onClick={() =>
                                    navigate(
                                      `/bootcamps/${bootcamp.id}/${course.id}`
                                    )
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: course.course_title,
                                  }}
                                ></CourseLink>
                                <td>{RoundToTwo(course.length / 60)}</td>
                                <td>
                                  {RoundToTwo(
                                    courseInteraction.average_watch_time / 60
                                  )}
                                </td>
                                <td>
                                  {RoundToTwo(
                                    courseInteraction.total_watch_time / 60
                                  )}
                                </td>
                                <td>{courseInteraction.completes}</td>
                                <td>{courseInteraction.unique_viewers}</td>
                                <td>{courseInteraction.submitted_questions}</td>
                              </StandardRow>
                            );
                          })()
                        )}
                      </tbody>
                    </ProgressTable>
                  </Card.Body>
                </Card>
              ))}
            </Card.Body>
          </Card>
        ))}
      </Card.Body>
    </Card>
  );
};

export default Bootcamp;
