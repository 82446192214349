export const Loading: React.FC = () => (
  <svg
    style={{
      margin: "auto",
      background: "none",
      display: "block",
      shapeRendering: "auto",
    }}
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="rotate(0 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.9333333333333333s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(24 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.8666666666666667s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(48 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.8s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(72 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.7333333333333333s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(96 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.6666666666666666s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(120 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.6s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(144 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5333333333333333s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(168 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.4666666666666667s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(192 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.4s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(216 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.3333333333333333s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(240 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.26666666666666666s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(264 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.2s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(288 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.13333333333333333s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(312 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.06666666666666667s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(336 50 50)">
      <rect
        x="47.5"
        y="27.5"
        rx="2.5"
        ry="2.5"
        width="5"
        height="5"
        fill="#003678"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
  </svg>
);
