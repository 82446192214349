export { };

declare global {
    interface String {
        toNumber(): number;
        removeNonAlphaNumeric(): string;
        normalizeGuid(): string;
    }
}

String.prototype.toNumber = function (this: string) { return parseFloat(this) };

String.prototype.removeNonAlphaNumeric = function (this: string) { return this.replace(/\W/g, "") }

String.prototype.normalizeGuid = function (this: string): string { return this.replace(/[^a-zA-Z0-9]/g, '').toUpperCase(); }