import { CSVLink } from "react-csv";
import axios, { AxiosError } from "axios";
import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { Loading } from "../Loading";
import { InputRowWrapper } from "../InputRow";
import { _subscriberList, _subscribers } from "../../interfaces/subscriberList";
import BootcampSelector from "../BootcampSelector";
import DoubleScrollbar from "../DoubleScrollBar";

const ProgressTable = styled.table.attrs({ className: "table" })`
  width: 100%;

  td,
  th {
    padding: 0.5rem 1rem;
  }

  thead,
  tfoot {
    background-color: #003678;
    color: white;
  }
`;

const StandardRow = styled.tr.attrs({ scope: "row" })`
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }

  cursor: pointer;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
  marginTop: 5rem;
`;

const HelpList = styled.ul`
  && {
    li {
      font-size: 1rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export const SubscriptionList: React.FC = () => {
  const [data, setData] = useState<_subscriberList>({ Items: [] });
  const [filteredData, setFilteredData] = useState<_subscribers[]>([]);
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>("");
  const [bootcamps, setBootcamps] = useState<{ id: string; title: string }[]>(
    []
  );
  const [selectedBootcamp, setSelectedBootcamp] = useState<string>("");
  const searchRef = useRef("");

  const handleLoadData = () => {
    const url = `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getmembersubscriptionlist?bootcampId=${selectedBootcamp}&subscriptionStatus=${subscriptionStatus}`;
    setLoading(true);

    axios({
      method: "Get",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage("");
          setData(response.data);
          setFilteredData(response.data.Items);
        } else {
          setMessage(response.statusText);
        }
      })
      .catch((error) => {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          setMessage("Subscriber not found");
          setData({ Items: [] });
        } else {
          setMessage(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchRef.current = e.target.value.toLowerCase();

    const tmp = data.Items.filter((subscriber) => {
      return (
        subscriber.FirstName.toLowerCase().includes(searchRef.current) ||
        subscriber.LastName.toLowerCase().includes(searchRef.current) ||
        subscriber.EmailAddress.toLowerCase().includes(searchRef.current)
      );
    });

    setFilteredData(tmp);
  };

  const handleSubscriptionStatusChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSubscriptionStatus(e.target.value);
  };

  const handleBootcampChange = (bootcampId: string) => {
    setSelectedBootcamp(bootcampId);
  };

  const headers = [
    { label: "Bootcamp ID", key: "BootcampId" },
    { label: "PMO ID", key: "PMOId" },
    { label: "Party ID", key: "PartyId" },
    { label: "First Name", key: "FirstName" },
    { label: "Last Name", key: "LastName" },
    { label: "Email Address", key: "EmailAddress" },
    { label: "ProfessionName", key: "ProfessionName" },
    { label: "Valid Until", key: "ValidUntil" },
    { label: "Revoked", key: "Revoked" },
    { label: "Payment Status", key: "StripePaymentStatus" },
    { label: "Initiated", key: "Initiated" },
    { label: "Stripe Session Id", key: "StripePaymentSessionId" },
    { label: "Coupon Name", key: "CouponName" },
    { label: "Promo Code", key: "PromoCode" },
    { label: "Surveys", key: "SubmittedEval" },
    { label: "Questions", key: "SubmittedQuestionSpotlight" },
  ];

  const dropdownValue = subscriptionStatus || "All";
  const bootCamp = bootcamps.find(
    (bootcamp) => bootcamp.id === selectedBootcamp
  );
  const formattedDate = Moment().format("YYYYMMDD-HHmmss");
  const filename = `BC-Subscriber-List-${
    bootCamp ? bootCamp.title : "All BC"
  }-${dropdownValue}-${formattedDate}.csv`;

  const csvData = filteredData.map((subscriber) => ({
    BootcampId: subscriber.BootcampId,
    PMOId: subscriber.PMOId,
    PartyId: subscriber.PartyId,
    FirstName: subscriber.FirstName,
    LastName: subscriber.LastName,
    EmailAddress: subscriber.EmailAddress,
    ProfessionName: subscriber.ProfessionName,
    ValidUntil: subscriber.ValidUntil
      ? Moment(subscriber.ValidUntil).format("MM/DD/yyyy")
      : "",
    Revoked: subscriber.Revoked
      ? Moment(subscriber.Revoked).format("MM/DD/yyyy")
      : "",
    StripePaymentStatus: subscriber.StripePaymentStatus,
    Initiated: subscriber.Initiated
      ? Moment(subscriber.Initiated).format("MM/DD/yyyy")
      : "",
    StripePaymentSessionId: subscriber.StripePaymentSessionId,
    CouponName: subscriber.CouponName,
    PromoCode: subscriber.PromoCode,
    SubmittedEval: subscriber.SubmittedEval,
    SubmittedQuestionSpotlight: subscriber.SubmittedQuestionSpotlight,
  }));

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getallbootcamps`
      )
      .then((response) => {
        const bootcampsData = response.data;
        const bootcamps = Object.keys(bootcampsData).map((key) => ({
          id: key,
          title: bootcampsData[key],
        }));
        setBootcamps(bootcamps);
      })
      .catch((error) => {
        console.log("Error fetching bootcamps:", error);
      });
  }, []);

  return (
    <>
      <h1>Subscriptions - {searchRef.current}</h1>
      {message && <p>{message}</p>}
      <Accordion className="mb-0">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Help and Definitions
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <h4>Definitions</h4>

              <HelpList className="help-list">
                <li>
                  <strong>Bootcamp Selector</strong> - This is a list of all
                  available Bootcamps in the system. Select a Bootcamp to filter
                  the list by that Bootcamp.
                </li>
                <li>
                  <strong>Subscription Status Selector</strong> - This is a list
                  of all available Subscription Statuses in the system. Select a
                  Subscription Status to filter the list by that Subscription
                  Status.
                </li>
                <ul>
                  <li>
                    <strong>Paid</strong> - The subscription has been paid for.
                  </li>
                  <li>
                    <strong>Granted</strong> - The subscription has been
                    granted.
                  </li>
                  <li>
                    <strong>Refunded</strong> - The subscription has been
                    refunded.
                  </li>
                  <li>
                    <strong>Unpaid, None</strong> - The subscription process has
                    been started, but user has abandoned their cart.
                  </li>
                </ul>
                <li>
                  <strong>Load Data</strong> - This button will load the list of
                  subscriptions based on the selected Bootcamp and Subscription
                  Status.
                </li>
                <li>
                  <strong>Export CSV</strong> - This button will export the list
                  of subscriptions based on the selected Bootcamp and
                  Subscription Status.
                </li>
                <li>
                  <strong>Email Filter</strong> - This field will filter the
                  list of subscriptions based on the entered text. This will
                  only work <i>after</i> the list is loaded. It is not true
                  search.
                </li>
              </HelpList>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <InputRowWrapper
        style={{ justifyContent: "space-between", alignItems: "flex-end" }}
      >
        <div className="d-flex flex-column">
          <label style={{ marginLeft: "1rem" }}>Bootcamp:</label>
          <BootcampSelector
            onSelectBootcamp={handleBootcampChange}
            isLoading={loading}
          />
        </div>

        <div className="d-flex flex-column">
          <label htmlFor="status" style={{ marginLeft: "1rem" }}>
            {" "}
            Subscription Status:
          </label>

          <select
            id="status"
            disabled={loading}
            title="Subscription Status"
            value={subscriptionStatus}
            onChange={handleSubscriptionStatusChange}
          >
            <option value="">All</option>
            <option value="paid">Paid</option>
            <option value="granted">Granted</option>
            <option value="refunded">Refunded</option>
            <option value="revoked">Revoked</option>
            <option value="expired">Expired</option>
            <option value="unpaid">Unpaid</option>
            <option value="none">None</option>
          </select>
        </div>
        <button
          disabled={loading}
          onClick={handleLoadData}
          className={!loading ? "cta cta--small cta--navy" : "disabled"}
          style={{
            padding: "0.8rem 1rem",
            borderRadius: "4px",
            fontWeight: 700,
          }}
        >
          <span>Load Data</span>
        </button>

        {!loading && data.Items.length > 0 ? (
          <CSVLink
            data={csvData}
            headers={headers}
            filename={filename}
            className="cta cta--small cta--navy "
            style={{
              borderRadius: "4px",
            }}
          >
            <span>Export CSV</span>
          </CSVLink>
        ) : (
          <button
            disabled={true}
            className="disabled"
            style={{
              padding: "0.8rem 1rem",
              borderRadius: "4px",
              fontWeight: 700,
            }}
          >
            <span>Export CSV</span>
          </button>
        )}
        <div className="d-flex flex-column">
          <label htmlFor="email">Email Filter:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            onChange={handleSearchChange}
            className={`${!loading && data.Items.length > 0 ? "" : "disabled"}`}
            disabled={loading && data.Items.length === 0}
          />
        </div>
      </InputRowWrapper>

      {loading ? (
        <LoadingWrapper>
          <Loading />
          <br />
          <p>Loading...</p>
        </LoadingWrapper>
      ) : (
        <DoubleScrollbar>
          <ProgressTable>
            <thead>
              <tr>
                <th scope="col">Bootcamp ID</th>
                <th scope="col">PMO ID</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email Address</th>
                <th scope="col">Profession</th>
                <th scope="col">Valid Until</th>
                <th scope="col">Revoked</th>
                <th scope="col">Payment Status</th>
                <th scope="col">Initiated</th>
                <th scope="col">
                  Stripe Session Id
                  <br />
                  (Click to Copy Value)
                </th>
                <th scope="col">Coupon Name</th>
                <th scope="col">Promo Code</th>
                <th scope="col">Surveys</th>
                <th scope="col">Questions</th>
              </tr>
            </thead>
            <tbody>
              {filteredData &&
                filteredData.map((subscriber, index) => (
                  <SubscriberItem
                    key={index}
                    subscriber={subscriber}
                    search={searchRef}
                    bootcampId={subscriber.BootcampId}
                  />
                ))}
            </tbody>
          </ProgressTable>
        </DoubleScrollbar>
      )}
    </>
  );
};

interface SubscriberItemProps {
  subscriber: _subscribers;
  search: React.MutableRefObject<string>;
  bootcampId: string;
}

const SubscriberItem: React.FC<SubscriberItemProps> = ({
  subscriber,
  search,
  bootcampId,
}) => {
  const navigate = useNavigate();

  const truncatedSessionId = `${subscriber.StripePaymentSessionId.substring(
    0,
    16
  )}${subscriber.StripePaymentSessionId.length > 16 ? "..." : ""}`;

  const truncatedBootcampId = `${bootcampId.substring(0, 7)}${
    bootcampId.length > 7 ? "..." : ""
  }`;

  return (
    <StandardRow>
      <td>
        <span title={bootcampId} style={{ cursor: "pointer" }}>
          {truncatedBootcampId}
        </span>
      </td>
      <td onClick={() => navigate(`/users/${subscriber.PMOId}`)}>
        {subscriber.PMOId}
      </td>
      <td>{subscriber.FirstName}</td>
      <td>{subscriber.LastName}</td>
      <td>{subscriber.EmailAddress}</td>
      <td>{subscriber.ProfessionName}</td>
      <td>
        {subscriber.ValidUntil &&
          Moment(subscriber.ValidUntil).format("MM/DD/yyyy")}
      </td>
      <td>
        {subscriber.Revoked && Moment(subscriber.Revoked).format("MM/DD/yyyy")}
      </td>
      <td>{subscriber.StripePaymentStatus}</td>
      <td>
        {subscriber.Initiated &&
          Moment(subscriber.Initiated).format("MM/DD/yyyy")}
      </td>
      <td>
        {/* Render truncated StripePaymentSessionId with tooltip */}
        <span
          title={subscriber.StripePaymentSessionId}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigator.clipboard.writeText(subscriber.StripePaymentSessionId);
            alert("Stripe Session Id copied to clipboard");
          }}
        >
          {truncatedSessionId}
        </span>
      </td>
      <td>{subscriber.CouponName}</td>
      <td>{subscriber.PromoCode}</td>
      <td onClick={() => navigate(`/surveys/${subscriber.PMOId}`)}>
        {subscriber.SubmittedEval}
      </td>
      <td onClick={() => navigate(`/questions/${subscriber.PMOId}`)}>
        {subscriber.SubmittedQuestionSpotlight}
      </td>
    </StandardRow>
  );
};
