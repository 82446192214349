import { SurveyQuestionData } from "../interfaces/surveys";
import { FetchData } from "../queries/fetcher";
import { getSurveyQuestionData } from "../queries/queries";

export const fetchSurveyQuestionData = async (path: string, id: string, title: string) => {
  try {
    const data = await FetchData<SurveyQuestionData>(
      getSurveyQuestionData(path)
    );
    const isParent =
      data &&
      (data?.item.StarRatings.results.length > 0 ||
        data?.item.table.results.length > 0);
    const result = {
      isParent: isParent,
      questionId: id,
      questions: data,
      title: title,
    };

    return result;
  } catch (error) {
    console.error(`Error fetching data :`, error);
    throw error;
  }
};