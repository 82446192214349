import { FinishedIcon, NotStartedIcon } from "./SvgIcons/SvgIcons";

export type SurveyRowTextProps = {
  rowText?: { value: string };
  id: string;
};

export type SurveyRowProps = SurveyRowTextProps & {
  headers: { value: string }[];
  answer?: string;
};

export const SurveyTableRow = (props: SurveyRowProps): JSX.Element => {
  return (
    <div className="d-none d-lg-flex align-items-center py-2">
      <div className="w-50 pl-2">{props?.rowText?.value}</div>
      <div className="w-50 pl-2">
        <div
          aria-labelledby="radio-buttons--label"
          style={{ display: "flex", flexWrap: "nowrap", width: "100%" }}
        >
          {props.headers.map((header, i) => (
            <div style={{ width: "33%", margin: "auto" }} key={i}>
              {header.value === props.answer ? (
                <FinishedIcon />
              ) : (
                <NotStartedIcon />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
