import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import { SurveyResult } from "../interfaces/memberSummary";
import { StarIcon } from "./SvgIcons/StarIcon";
import {
  FinishedIcon,
  NotStartedIcon,
} from "../components/SvgIcons/SvgIcons";

export interface SubmittedSurveysProps {
  surveyResults: SurveyResult[];
}

const SubmittedSurveys: React.FC<SubmittedSurveysProps> = ({ surveyResults }) => {
  if (surveyResults.length === 0) return <p>No surveys submitted</p>;

  return (
    <>
      {surveyResults.map((surveyResult) => (
        <SingleSurvey key={surveyResult.SurveyResultsData.id} {...surveyResult} />
      ))}
    </>
  );
};

const SingleSurveyWrapper = styled.div`
  margin: 2rem 0;
  background-color: var(--bg-light);
  padding: 1rem;
  border: 1px solid var(--pm-blue);
`;

const ResponseSet = styled.div``;
const QuestionGroup = styled.div`
  margin-bottom: 3rem;

  .heading {
    font-weight: bold;
  }

  .inner {
    margin: 0 2rem;
  }
`;

const SurveyLink = styled.a`
  color: var(--pm-blue);  

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
`;

const ResponseContent = styled.div`
  padding: 0 2rem;
`;

export const SingleSurvey: React.FC<SurveyResult> = (surveyResult) => {
  const navigate = useNavigate()
  return (
    <SingleSurveyWrapper>
      <p>
        <strong>
          Submitted on {Moment(surveyResult.SurveyResultsData.submission_date).format("MM/DD/yyyy")} by <SurveyLink onClick={() => navigate(`/users/${surveyResult.PMOId}`)}>{surveyResult.FirstName} {surveyResult.LastName} ({surveyResult.EmailAddress})</SurveyLink>
        </strong>
      </p>
      <ResponseSet>
       <QuestionGroup>
          <p className="heading">
            1. Overall, how satisfied are you with Primary Care Bootcamp for NPs and PAs?
          </p>
          <ResponseScaleStars scale={surveyResult.SurveyResultsData.satisfaction} />
        </QuestionGroup>
        <QuestionGroup>
          <p className="heading">
            2. If asked by a colleague or friend, how likely would you be to recommend Primary Care Bootcamp?
          </p>
          <ResponseScaleTen scale={surveyResult.SurveyResultsData.rating_recommend} />
        </QuestionGroup>
        <QuestionGroup>
          <p className="heading">
            3. Would you recommend Primary Care Bootcamp to your employer as a supplement to onboarding for new employees?
          </p>
          <div className="inner">
            <RadioResponses marked={surveyResult.SurveyResultsData.recommend_yes} label="Yes" />
            <RadioResponses marked={surveyResult.SurveyResultsData.recommend_no} label="No" />
          </div>
        </QuestionGroup>
        <QuestionGroup>
          <p className="heading">
            4. How much do you agree or disagree with the following statements?
          </p>
          <div className="inner">
            <p>
              The faculty demonstrated experiential knowledge of their topic.
              <ResponseScaleStars scale={surveyResult.SurveyResultsData.knowledge} />
            </p>
            <p>
              Education content achieved the learning objectives.
              <ResponseScaleStars scale={surveyResult.SurveyResultsData.objectives} />
            </p>
            <p>
              Education content was free of commercial bias.
              <ResponseScaleStars scale={surveyResult.SurveyResultsData.bias} />
            </p>
            <p>
              Education content provided fair and balanced coverage of the topics.
              <ResponseScaleStars scale={surveyResult.SurveyResultsData.balanced} />
            </p>
            <p>
              Based on what I learned, I plan to change my practice.
              <ResponseScaleStars scale={surveyResult.SurveyResultsData.plan_change_practice} />
            </p>
            <p>
              Based on what I learned, I feel more confident in managing patients.
              <ResponseScaleStars scale={surveyResult.SurveyResultsData.confidence_managing_patients} />
            </p>
            <p>
              Based on what I learned, I feel more confident in addressing issues that come up in everyday practice.
              <ResponseScaleStars scale={surveyResult.SurveyResultsData.confidence_addressing_issues} />
            </p>
          </div>
        </QuestionGroup>
        <QuestionGroup>
          <p className="heading">
            5. The following resources are useful in supporting improvement in my practice:
          </p>
          <div className="inner">
            <p>
              Downloadable Slides
              <ResponseScaleStars scale={surveyResult.SurveyResultsData.useful_downloadable_slides} />
            </p>
            <p>
              Downloadable Course Summaries
              <ResponseScaleStars scale={surveyResult.SurveyResultsData.useful_downloadable_course_summary} />
            </p>
            <p>
              Question Spotlights
              <ResponseScaleStars scale={surveyResult.SurveyResultsData.useful_question_spotlight} />
            </p>
          </div>
        </QuestionGroup>
        <QuestionGroup>
          <p className="heading">
            6. I plan to make the following changes to my practice (select all that apply):
          </p>
          <table className="table table-hover table-striped">
            <tbody>
            <tr className="d-flex flex-row" style={{ flexWrap: 'nowrap' }}>
              <th style={{ width: '420px' }}></th>
              <th style={{ width: '140px' }}>I have already made this change</th>
              <th style={{ width: '130px' }}>I plan to make this change</th>
              <th style={{ width: '130px' }}>No change</th>
            </tr>
            <tr className="d-flex flex-row">
              <th style={{ width: '420px' }}>Modify approaches to common chief complaints</th>
              <td style={{ width: '140px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.common_chief_complaints_already_changed} label="" />                
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.common_chief_complaints_plan_change} label="" />                
              </td            >
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.common_chief_complaints_no_change} label="" />                
              </td>
            </tr>
            <tr className="d-flex flex-row">
              <th style={{ width: '420px' }}>Implement strategies to increase efficiency</th>
              <td style={{ width: '140px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.increase_efficiency_already_changed} label="" />                
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.increase_efficiency_plan_change} label="" />               
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.increase_efficiency_no_change} label="" />                
              </td>
            </tr>
            <tr className="d-flex flex-row">
              <th style={{ width: '420px' }}>Update screening and tests I use to aid in diagnosis</th>
              <td style={{ width: '140px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.utilize_screening_already_changed} label="" />                
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.utilize_screening_plan_change} label="" />                
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.utilize_screening_no_change} label="" />                
              </td>
            </tr>
            <tr className="d-flex flex-row">
              <th style={{ width: '420px' }}>Modify communication with patients, partners, caregivers and/or members of the healthcare team</th>
              <td style={{ width: '140px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.modify_communication_already_changed} label="" />                
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.modify_communication_plan_change} label="" />              
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.modify_communication_no_change} label="" />                
              </td>
            </tr>
            <tr className="d-flex flex-row">
              <th style={{ width: '420px' }}>Update the resources I use to address patients' clinical needs</th>
              <td style={{ width: '140px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.utilize_resources_already_changed} label="" />                
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.utilize_resources_plan_change} label="" />                
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.utilize_resources_no_change} label="" />                
              </td>
            </tr>
            <tr className="d-flex flex-row">
              <th style={{ width: '420px' }}>Discuss new learnings with my peers</th>
              <td style={{ width: '140px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.discuss_new_learnings_already_changed} label="" />                
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.discuss_new_learnings_plan_change} label="" />                
              </td>
              <td style={{ width: '130px' }}>
                <RadioResponses marked={surveyResult.SurveyResultsData.discuss_new_learnings_no_change} label="" />                
              </td>
            </tr>
            <tr className="d-flex flex-row">
              <th style={{ width: '420px' }}>Other</th>
              <td style={{ width: '140px' }}>{surveyResult.SurveyResultsData.other_comment}</td>
            </tr>
            </tbody>
          </table>
        </QuestionGroup>
        <QuestionGroup>
          <div>
            <p className="heading">
              7. How many years have you been in practice?
            </p>

            <div className="inner">
              <RadioResponses
                marked={surveyResult.SurveyResultsData.less_than_one_year}
                label="Less than 1 year"
              />
              <RadioResponses
                marked={surveyResult.SurveyResultsData.one_to_two_years}
                label="1-2 years"
              />
              <RadioResponses
                marked={surveyResult.SurveyResultsData.three_to_four_years}
                label="3-4 years"
              />
              <RadioResponses
                marked={surveyResult.SurveyResultsData.five_or_more_years}
                label="5 or more years"
              />
            </div>
          </div>
        </QuestionGroup>
        <QuestionGroup>
          <p className="heading">
            8. Please comment on appropriateness of faculty, content, relevance,
            format, or bias:
          </p>
          <ResponseContent>
            <p>{surveyResult.SurveyResultsData.comment_appropriateness}</p>
          </ResponseContent>
        </QuestionGroup> 
        <QuestionGroup>
          <p className="heading">
            9. Please share any additonal feedback:
          </p>
          <ResponseContent>
            <p>{surveyResult.SurveyResultsData.comment}</p>
          </ResponseContent>
        </QuestionGroup> 
      </ResponseSet>
    </SingleSurveyWrapper>
  );
};

export interface ResponseScaleProps {
  scale: number;
}

export interface RadioProps {
  marked: boolean;
  label: string;
}

const ResponseScaleWrapper = styled.span`
  display: flex;

  .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 0.125rem;
    margin: 0 0.125rem;
    transition: background 0.2s ease-in-out;
  }

  .highlight {
    svg {
      fill: #003678;
    }
  }
`;

export const ResponseScaleStars: React.FC<ResponseScaleProps> = ({ scale }) => {
  return (
    <ResponseScaleWrapper>
      {[...Array(5)].map((star, i) => {
        return (
          <span
          key={i}
            className={`img-wrapper ${i} ${scale >= i + 1 ? "highlight" : "standard"
              }`}
          >
            <StarIcon />
          </span>
        );
      })}
      <span className="img-wrapper">({scale})</span>
    </ResponseScaleWrapper>
  );
};

export const ResponseScaleTen: React.FC<ResponseScaleProps> = ({ scale }) => {
  return (
    <ResponseScaleWrapper>
      {[...Array(10)].map((star, i) => {
        return (
          <span
             key={i}
            className={`img-wrapper ${i} ${scale >= i + 1 ? "highlight" : "standard"
              }`}
          >
            <StarIcon />
          </span>
        );
      })}
      <span className="img-wrapper">({scale})</span>
    </ResponseScaleWrapper>
  );
};

export const RadioResponses: React.FC<RadioProps> = ({ marked, label }) => {return(
  <div>{marked ? <FinishedIcon/> : <NotStartedIcon/>} <label>{label}</label></div>
)};

export default SubmittedSurveys;
