import { jwtDecode } from "jwt-decode";
import moment from "moment";
import * as BrowserStorage from "../constants/BrowserStorage";
import { useState, useEffect } from "react";

export const useAuth = () => {
    const [auth, setAuth] = useState<string | null>(localStorage.getItem(BrowserStorage.ACCESS_TOKEN));
  
    useEffect(() => {
  
      if (auth) {

        const checkExpiration = () => {
          const decodedToken = jwtDecode(auth) as { exp: number };
          const expirationTime = moment.unix(decodedToken.exp).toDate();

          if (moment().isAfter(expirationTime)) {
            // Token expired
            setAuth(null);
            clearAuthTokens();
          } else {
            // Token valid
            return true;
          }
        };
 
        const intervalId = setInterval(checkExpiration, 60000);
        return () => clearInterval(intervalId);
      }
    }, []);

    return auth;
};

export function clearAuthTokens() {
  window.localStorage.removeItem(BrowserStorage.ACCESS_TOKEN);
}

export function saveAuthTokens(tokens: { accessToken: string }) {
  window.localStorage.setItem(BrowserStorage.ACCESS_TOKEN, tokens.accessToken);
}
