import styled from "styled-components";

const InputRowWrapper = styled.div`
  margin: 2rem 0;

  display: flex;
  align-items: center;

  input {
    width: 350px;
    margin-right: 1rem;
  }

  button {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    background-color: #003678;
    color: white;
    border: none;
    border-radius: 4px;
  }

  select {
    margin-left: 1rem;
    padding: 0.5rem;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
};
`;

export { InputRowWrapper }