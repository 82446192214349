export const StarIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M10.9022 10.6692L13.7196 5.20895L16.5479 10.2402L16.775 10.6444L17.2302 10.7321L23.5833 11.9571L19.3818 16.4092L19.0575 16.7529L19.1171 17.2217L19.962 23.8656L14.8493 21.1715L14.3944 20.9318L13.9347 21.1624L8.23584 24.0214L9.11465 17.9975L9.18278 17.5305L8.86561 17.181L4.34395 12.1991L10.1829 11.1962L10.6738 11.1119L10.9022 10.6692Z"
      stroke="#003678"
      strokeWidth="2"
    />
  </svg>
);
