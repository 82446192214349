import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
:root {
  --bg-light: #f8f8f8;
  --bg-medium: #d8e5f0;
  --pm-blue: #003678;
  --pm-green: #00969f
}
`;

export default GlobalStyle;
