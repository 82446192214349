import styled from "styled-components";

interface GrantModalProps {
  emailAddress: string;
  bootcampId: string;
  onRevoke: (bootcampId: string) => void;
  onClose: () => void;
}

const GrantModalWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  background-color: white;
  margin: auto auto;
  width: 500px;
  padding: 4rem;

  input {
    width: 75px;
  }

  .error {
    color: red;
    font-size: 0.85rem;
    margin: 1rem 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;

  button {
    margin-left: 1rem;
  }
`;

const RevokeModal: React.FC<GrantModalProps> = ({
  emailAddress,
  bootcampId,
  onClose,
  onRevoke,
}) => {
  return (
    <GrantModalWrapper>
      <InnerWrapper>
        <h3>Revoke User Access</h3>
        <p>Are you sure you want to revoke access for {emailAddress}?</p>
        <ButtonWrapper>
          <button
            className="cta cta--small cta--navy-border"
            onClick={() => onRevoke(bootcampId)}
          >
            Yes
          </button>
          <button
            className="cta cta--small cta--navy"
            onClick={() => onClose()}
          >
            <span>No</span>
          </button>
        </ButtonWrapper>
      </InnerWrapper>
    </GrantModalWrapper>
  );
};

export default RevokeModal;
