import styled from "styled-components";
import { useRef, useState } from "react";

interface GrantModalProps {
  emailAddress: string;
  bootcampId: string;
  onGrant: (days: number, bootcampId: string) => void;
  onClose: () => void;
}

const GrantModalWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  background-color: white;
  margin: auto auto;
  width: 500px;
  padding: 4rem;

  input {
    width: 75px;
  }

  .error {
    color: red;
    font-size: 0.85rem;
    margin: 1rem 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;

  button {
    margin-left: 1rem;
  }
`;

const GrantModal: React.FC<GrantModalProps> = ({
  emailAddress,
  bootcampId,
  onClose,
  onGrant,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState<string>("");

  const handleGrant = () => {
    const days = (inputRef.current?.value || 0) as number;

    if (days > 0) {
      onGrant(days, bootcampId);
      setMessage("");
    } else {
      setMessage("Please enter a valid number of days");
    }
  };

  return (
    <GrantModalWrapper>
      <InnerWrapper>
        <h3>Grant User Access</h3>
        Grant access for <input type="text" ref={inputRef} /> days
        {message && <p className="error">{message}</p>}
        <ButtonWrapper>
          <button
            className="cta cta--small cta--navy-border"
            onClick={() => handleGrant()}
          >
            Grant
          </button>
          <button
            className="cta cta--small cta--navy"
            onClick={() => onClose()}
          >
            <span>Cancel</span>
          </button>
        </ButtonWrapper>
      </InnerWrapper>
    </GrantModalWrapper>
  );
};

export default GrantModal;
