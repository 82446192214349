import styled from "styled-components";
import { SurveyTableRow } from "./SurveyTableRow";
import { SurveyTablePropsV2 } from "../interfaces/surveys";
import { FinishedIcon, NotStartedIcon } from "./SvgIcons/SvgIcons";

const SurveyTableWrapper = styled.div`
  @media (min-width: 992px) {
    > div:nth-child(even) {
      background-color: rgb(248, 248, 248);

      &:last-child {
        background-color: white;
      }
    }
  }
`;

const SurveyTable = (props: SurveyTablePropsV2): JSX.Element => {
  const headers = [
    props.tableHeadOne,
    props.tableHeadTwo,
    props.tableHeadThree,
  ];

  const includeOther = props.includeOther.jsonValue.value || false;
  return (
    <SurveyTableWrapper>
      <div className="row mb-3 d-none d-lg-flex">
        <div className="col-6"> </div>
        <div className="col-2">{props.tableHeadOne.value}</div>
        <div className="col-2">{props.tableHeadTwo.value}</div>
        <div className="col-2">{props.tableHeadThree.value}</div>
      </div>
      {props.tableRows.results.map((row, index) => (
        <SurveyTableRow
          headers={headers}
          key={"row-" + index}
          {...row}
          answer={row.answer}
        />
      ))}
      {includeOther && (
        <div className="py-2">
          <div className="d-flex align-items-center mb-3">
  
              {!!props.includeOther.answer ? (
                <FinishedIcon />
              ) : (
                <NotStartedIcon />
              )}
       
            <div className="pl-2">Other (please explain in text box below)</div>
          </div>
          <p>{props.includeOther.answer }</p>
        </div>
      )}
    </SurveyTableWrapper>
  );
};

export default SurveyTable;
