import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getQuestionId } from "../components/SubmittedQuestions";
import { ExclamationIcon } from "./SvgIcons/ExclamationIcon";
import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import {
  CreditSummary,
  Progress,
  QuestionResult,
} from "../interfaces/memberSummary";
import {
  GetCourseProgressIcon,
  SecondsToMinutesAndSeconds,
  GetTotalTime,
} from "../utils/ProgressUtil";

const Wrapper = styled.section`
  margin: 2rem 0;
`;

const ProgressTable = styled.table`
  width: 100%;

  td,
  th {
    padding: 0.5rem 1rem;
  }

  thead,
  tfoot {
    background-color: #003678;
    color: white;
  }
`;

const StandardRow = styled.tr`
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const IconWrapper = styled.span`
  position: relative;
  top: -8px;
  left: 4px;
`;

const ActivityHistory = styled.div`
  max-width: 500px;
  padding-left: 14px;
`;

export interface MyProgressProps {
  name: string;
  progress: Progress;
  creditSummary: CreditSummary;
  questions: QuestionResult[];
  partyId: number;
  isCourseCompleteDataLoading: boolean;
  isDataLoading: boolean;
  handleUpdateCourseStatus: (
    courseId: string,
    bootcamp_id: string,
    partyId: number
  ) => void;
}

const ProgressSummary: React.FC<MyProgressProps> = ({
  name,
  progress,
  creditSummary,
  questions,
  partyId,
  isDataLoading,
  handleUpdateCourseStatus,
  isCourseCompleteDataLoading,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<string>("");
  const [modalMessage, setModalMessage] = useState<string>("");
  const [idx, setIdx] = useState<number[] | null>(null);

  const handleCloseModal = () => setShowModal(false);

  const handleConfirmModal = (courseId: string, bootcamp_id: string) => {
    setShowModal(false);
    handleUpdateCourseStatus(courseId, bootcamp_id, partyId);
  };

  function creditSummaryHTML() {
    return { __html: creditSummary?.awards[0] };
  }

  const getQuestionCount = (courseId: string): number => {
    return questions.filter(
      (question) => question.SubmitQuestionData.CourseId === courseId
    ).length;
  };

  const handleButtonClick = (courseId: string) => {
    setSelectedCourse(courseId);
    setShowModal(true);
    setModalMessage(
      "Are you sure that you want to mark this course as completed?"
    );
  };

  return (
    <Wrapper>
      <h3>Progress Summary - {name}</h3>
      <p dangerouslySetInnerHTML={creditSummaryHTML()}></p>
      <p>
        Completed {progress?.completed_courses} of {progress?.total_courses}{" "}
        courses
      </p>

      <ProgressTable>
        <thead>
          <tr>
            <th>Course Title</th>
            <th>Completed</th>
            <th title="time as mm:ss">Time Spent</th>
            <th title="length as mm:ss">Length</th>
            <th title="progress as mm:ss">Progress</th>
            <th title="credited time as mm:ss">Credited Time</th>
            <th>Questions</th>
            <th>Mark as Complete</th>
          </tr>
        </thead>
        <tbody>
          {progress?.progress_records.map((record, index) => {
            return (
              <StandardRow key={record.id}>
                <Accordion style={{ marginBottom: "0px" }}>
                  <Accordion.Toggle
                    as="div"
                    style={{ cursor: "pointer" }}
                    eventKey={index.toString()}
                    onClick={() => {
                      if (idx && idx.includes(index))
                        setIdx(idx.filter((i) => i !== index));
                      else if (idx) {setIdx([...idx, index])};
                    }}
                  >
                    <td style={{ maxWidth: "500px" }}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: record.course_title,
                        }}
                      />
                      {record.activity_history[0] ? (
                        <IconWrapper>
                          <ExclamationIcon />
                        </IconWrapper>
                      ) : null}
                    </td>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index.toString()}>
                    {
                      <ActivityHistory>
                        {record.activity_history[0]
                          ? record.activity_history[0].PreviousState
                            ? record.activity_history[0]?.PreviousState
                            : "No previous state"
                          : ""}
                      </ActivityHistory>
                    }
                  </Accordion.Collapse>
                </Accordion>
                <td>{GetCourseProgressIcon(record)}</td>
                <td>{SecondsToMinutesAndSeconds(record.time)}</td>
                <td>{SecondsToMinutesAndSeconds(record.length)}</td>
                <td>{SecondsToMinutesAndSeconds(record.progress)}</td>
                <td>{SecondsToMinutesAndSeconds(record.credited_time)}</td>
                <td>
                  {" "}
                  {(() => {
                    const questionCount = getQuestionCount(record.course_id);
                    return questionCount > 0 ? (
                      <a href={"#" + getQuestionId(record.course_id)}>
                        {questionCount}
                      </a>
                    ) : (
                      <>{questionCount}</>
                    );
                  })()}
                </td>
                <td>
                  {record.completed ? (
                    record.activity_history[0] ? (
                      <span>
                        {Moment(record.activity_history[0].ModifiedDate).format(
                          "MM/DD/yyyy"
                        )}
                        -{record.activity_history[0].ModifiedBy}
                      </span>
                    ) : null
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-link p-0"
                        onClick={() => handleButtonClick(record.course_id)}
                      >
                        {(isCourseCompleteDataLoading || isDataLoading) &&
                        selectedCourse === record.course_id ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <span>Mark as Complete</span>
                        )}
                      </button>
                    </>
                  )}
                </td>
              </StandardRow>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>Total:</td>
            <td></td>
            <td>{GetTotalTime(progress?.progress_records, "time")}</td>
            <td>{GetTotalTime(progress?.progress_records, "length")}</td>
            <td>{GetTotalTime(progress?.progress_records, "progress")}</td>
            <td>{GetTotalTime(progress?.progress_records, "credited_time")}</td>
            <td>{questions.length}</td>
          </tr>
        </tfoot>
      </ProgressTable>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Completion</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              handleConfirmModal(selectedCourse, progress?.bootcamp_id)
            }
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};

export default ProgressSummary;
