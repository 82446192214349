import axios, { AxiosError } from "axios";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SurveyResult } from "../../interfaces/memberSummary";
import SubmittedSurveys from "../SubmittedSurveys";
import { Loading } from "../Loading";
import BootcampSelector from "../BootcampSelector";
import { CSVLink } from "react-csv";
import { InputRowWrapper } from "../InputRow";

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 5rem;
`;

export const Surveys: React.FC = () => {
  const { pmoId } = useParams();
  const [data, setData] = useState<SurveyResult[] | null>(null);
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBootcamp, setSelectedBootcamp] = useState<string>("");

  const handleBootcampChange = (bootcampId: string) => {
    setSelectedBootcamp(bootcampId);
  };

  const handleLoadData = () => {
    const url = `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getsurveyresponses?bootcampId=${selectedBootcamp}&pmoId=${pmoId ? `${pmoId}` : ""}`;
    setLoading(true);

    axios({
      method: "Get",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage("");
          setData(response.data.Items);
        } else {
          setMessage(response.statusText);
        }
      })
      .catch((error) => {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          setMessage("Survey responses not found");
          setData(null);
        } else {
          setMessage(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const headers = [
    { label: "PMOId", key: "PMOId" },
    { label: "FirstName", key: "FirstName" },
    { label: "LastName", key: "LastName" },
    { label: "EmailAddress", key: "EmailAddress" },
    { label: "Id", key: "id" },
    { label: "PartyId", key: "partyId" },
    { label: "Submission Date", key: "submission_date" },
    { label: "BootcampId", key: "bootcamp_id" },
    { label: "Satisfaction", key: "satisfaction" },
    { label: "Rating Recommend", key: "rating_recommend" },
    { label: "Recommend Yes", key: "recommend_yes" },
    { label: "Recommend No", key: "recommend_no" },
    { label: "Knowledge", key: "knowledge" },
    { label: "Objectives", key: "objectives" },
    { label: "Bias", key: "bias" },
    { label: "Balanced", key: "balanced" },
    { label: "Plan Change Practice", key: "plan_change_practice" },
    { label: "Plan Adhere Guidelines", key: "plan_adhere_guidelines" },
    { label: "Plan Incorporate Strategies", key: "plan_incorporate_strategies" },
    { label: "Plan Modify Communications", key: "plan_modify_communications" },
    { label: "Plan Modify Treatment", key: "plan_modify_treatment" },
    { label: "Plan Discuss Learnings", key: "plan_discuss_learnings" },
    { label: "Plan None", key: "plan_none" },
    { label: "Plan Other", key: "plan_other" },
    { label: "Plan Other Text", key: "plan_other_text" },
    { label: "Confidence Managing Patients", key: "confidence_managing_patients" },
    { label: "Confidence Addressing Issues", key: "confidence_addressing_issues" },
    { label: "Useful Downloadable Slides", key: "useful_downloadable_slides" },
    { label: "Useful Downloadable Course Summary", key: "useful_downloadable_course_summary" },
    { label: "Useful Question Spotlight", key: "useful_question_spotlight" },
    { label: "Confidence Support Strategies", key: "confidence_support_strategies" },
    { label: "Common Chief Complaints Already Changed", key: "common_chief_complaints_already_changed" },
    { label: "Common Chief Complaints Plan Change", key: "common_chief_complaints_plan_change" },
    { label: "Common Chief Complaints No Change", key: "common_chief_complaints_no_change" },
    { label: "Increase Efficiency Already Changed", key: "increase_efficiency_already_changed" },
    { label: "Increase Efficiency Plan Change", key: "increase_efficiency_plan_change" },
    { label: "Increase Efficiency No Change", key: "increase_efficiency_no_change" },
    { label: "Utilize Screening Already Changed", key: "utilize_screening_already_changed" },
    { label: "Utilize Screening Plan Change", key: "utilize_screening_plan_change" },
    { label: "Utilize Screening No Change", key: "utilize_screening_no_change" },
    { label: "Modify Communication Already Changed", key: "modify_communication_already_changed" },
    { label: "Modify Communication Plan Change", key: "modify_communication_plan_change" },
    { label: "Modify Communication No Change", key: "modify_communication_no_change" },
    { label: "Utilize Resources Already Changed", key: "utilize_resources_already_changed" },
    { label: "Utilize Resources Plan Change", key: "utilize_resources_plan_change" },
    { label: "Utilize Resources No Change", key: "utilize_resources_no_change" },
    { label: "Discuss New Learnings Already Changed", key: "discuss_new_learnings_already_changed" },
    { label: "Discuss New Learnings Plan Change", key: "discuss_new_learnings_plan_change" },
    { label: "Discuss New Learnings No Change", key: "discuss_new_learnings_no_change" },
    { label: "Has Other Comment", key: "has_other_comment" },
    { label: "Other Comment", key: "other_comment" },
    { label: "Less Than One Year", key: "less_than_one_year" },
    { label: "One To Two Years", key: "one_to_two_years" },
    { label: "Three To Four Years", key: "three_to_four_years" },
    { label: "Five Or More Years", key: "five_or_more_years" },
    { label: "CommentAppropriateness", key: "comment_appropriateness" },
    { label: "Comment", key: "comment" }    
];

const csvData = data
  ? data.map((surveyResult) => ({
      PMOId: surveyResult.PMOId,
      FirstName: surveyResult.FirstName,
      LastName: surveyResult.LastName,
      EmailAddress: surveyResult.EmailAddress,
      ...surveyResult.SurveyResultsData,
    }))
  : [];

  const formattedDate = new Date().toISOString().replace(/[-:.TZ]/g, "");
  const filename = `Survey-Report-${formattedDate}.csv`;

  useEffect(() => {
    if (pmoId) {
      handleLoadData();
    }
  }, [pmoId]);

  if (loading) {
    return (
      <LoadingWrapper>
        <Loading />
        <br />
        <p>Loading...</p>
      </LoadingWrapper>
    );
  }

  return (
    <>
      <h1>Surveys</h1>
      {message && <p>{message}</p>}
      <InputRowWrapper>
        Bootcamp:
        <BootcampSelector onSelectBootcamp={handleBootcampChange} />
        <button
          onClick={handleLoadData}
          className="cta cta--small cta--navy"
        >
          <span>Load Data</span>
        </button>
        {data && data.length > 0 && (
          <CSVLink
            data={csvData}
            headers={headers}
            filename={filename}
            className="cta cta--small cta--navy"
            style={{ borderRadius: "4px", margin: "0 1rem" }}
          >
            <span>Export CSV</span>
          </CSVLink>
        )}
      </InputRowWrapper>
      {data && <SubmittedSurveys surveyResults={data} />}
    </>
  );
};