import styled from "styled-components";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Survey,
  SurveyQuestionData,
  SurveyResponses,
} from "../interfaces/surveys";
import SurveyQuestionV2 from "./SurveyQuestionV2";
import { Loading } from "./Loading";
import { Card } from "react-bootstrap";
import { fetchSurveyQuestionData } from "../utils/SurveyUtil";
import { FinishedIcon, NotStartedIcon } from "./SvgIcons/SvgIcons";

const SurveyLink = styled.a`
  color: var(--pm-blue);

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 5rem;
`;

export interface SurveyV2Props {
  surveyResponses: SurveyResponses;
  surveys: Survey[];
}

const SurveyV2: React.FC<SurveyV2Props> = ({ surveyResponses, surveys }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [surveyQuestions, setSurveyQuestions] = useState<
    {
      isParent: boolean | null;
      questionId: string;
      questions: SurveyQuestionData;
      title: string;
    }[]
  >([]);

  useEffect(() => {
    setLoading(true);

    const fetchDataAndSetState = async () => {
      try {
        setLoading(true);

        const fetchDataPromises = surveys.flatMap((survey) =>
          survey.surveyQuestion.results.map(
            ({ path, id, question: { value: title } }) =>
              fetchSurveyQuestionData(path, id, title)
          )
        );

        const dataArray = await Promise.all(fetchDataPromises);
        const filteredDataArray = dataArray.filter(
          (data) => data !== undefined && data !== null
        );

        setSurveyQuestions(filteredDataArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndSetState();
  }, [surveys]);

  if (loading) {
    return (
      <LoadingWrapper>
        <Loading />
        <br />
        <p>Loading...</p>
      </LoadingWrapper>
    );
  }

  return (
    <>
      {surveyResponses &&
        surveyResponses.Items.map((item, i) => (
          <Card key={item.SubmissionId + i} className="mt-5">
            <Card.Header>
              <p>
                <strong>
                  Submitted on {Moment(item.SubmitedDate).format("MM/DD/yyyy")}{" "}
                  by{" "}
                  <SurveyLink onClick={() => navigate(`/users/${item.PMOId}`)}>
                    {item.FirstName} {item.LastName} ({item.EmailAddress})
                  </SurveyLink>
                </strong>
              </p>
            </Card.Header>
            <Card.Body>
              {surveyQuestions?.map((question, index) => (
                <SurveyQuestionV2
                  key={index}
                  result={item.SurveyResultsData}
                  {...question}
                  index={index}
                />
              ))}
            </Card.Body>
            <Card.Footer>
              <div className="d-flex align-items-center">
                {item.GaveMarketingConsent ? (
                  <FinishedIcon />
                ) : (
                  <NotStartedIcon />
                )}

                <div className="pl-2 ">Marketing Consent</div>
              </div>
            </Card.Footer>
          </Card>
        ))}
    </>
  );
};
export default SurveyV2;
