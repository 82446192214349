import { FC } from "react";
import { SvgIconConstituentValueProps } from "../../interfaces/SvgIconProps";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SvgIconProps extends FC<SvgIconConstituentValueProps> {}

export const FinishedIcon: SvgIconProps = ({
  width = "20",
  height = "20",
  fillColor = "black",
  fillOpacity = "0.87",
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 1.66669C5.40033 1.66669 1.66699 5.40002 1.66699 10C1.66699 14.6 5.40033 18.3334 10.0003 18.3334C14.6003 18.3334 18.3337 14.6 18.3337 10C18.3337 5.40002 14.6003 1.66669 10.0003 1.66669ZM8.33366 14.1667L4.16699 10L5.34199 8.82502L8.33366 11.8084L14.6587 5.48335L15.8337 6.66669L8.33366 14.1667Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export const InProgressIcon: SvgIconProps = ({
  width = "20",
  height = "20",
  fillColor = "black",
  fillOpacity = "0.87",
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" fill="black" />
      <rect x="2" y="2" width="8" height="16" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 1.66667C5.40032 1.66667 1.66699 5.4 1.66699 10C1.66699 14.6 5.40032 18.3333 10.0003 18.3333C14.6003 18.3333 18.3337 14.6 18.3337 10C18.3337 5.4 14.6003 1.66667 10.0003 1.66667ZM10.0003 16.6667C6.31699 16.6667 3.33366 13.6833 3.33366 10C3.33366 6.31667 6.31699 3.33334 10.0003 3.33334C13.6837 3.33334 16.667 6.31667 16.667 10C16.667 13.6833 13.6837 16.6667 10.0003 16.6667Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export const ReadyIcon: SvgIconProps = ({
  width = "20",
  height = "20",
  fillColor = "black",
  fillOpacity = "0.87",
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 1.66667C5.40033 1.66667 1.66699 5.40001 1.66699 10C1.66699 14.6 5.40033 18.3333 10.0003 18.3333C14.6003 18.3333 18.3337 14.6 18.3337 10C18.3337 5.40001 14.6003 1.66667 10.0003 1.66667ZM10.0003 16.6667C6.31699 16.6667 3.33366 13.6833 3.33366 10C3.33366 6.31667 6.31699 3.33334 10.0003 3.33334C13.6837 3.33334 16.667 6.31667 16.667 10C16.667 13.6833 13.6837 16.6667 10.0003 16.6667Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export const PlayingIcon: SvgIconProps = ({
  width = "18",
  height = "18",
  fillColor = "black",
  fillOpacity = "0.87",
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666992 8.99999C0.666992 4.39999 4.40033 0.666656 9.00033 0.666656C13.6003 0.666656 17.3337 4.39999 17.3337 8.99999C17.3337 13.6 13.6003 17.3333 9.00033 17.3333C4.40033 17.3333 0.666992 13.6 0.666992 8.99999ZM6.50037 14.1962L14.0004 9L6.50037 3.80385V14.1962Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export const NotStartedIcon: SvgIconProps = ({
  width = "18",
  height = "18",
  fillColor = "black",
  fillOpacity = "0.87",
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00033 0.666672C4.40033 0.666672 0.666992 4.40001 0.666992 9.00001C0.666992 13.6 4.40033 17.3333 9.00033 17.3333C13.6003 17.3333 17.3337 13.6 17.3337 9.00001C17.3337 4.40001 13.6003 0.666672 9.00033 0.666672ZM9.00033 15.6667C5.31699 15.6667 2.33366 12.6833 2.33366 9C2.33366 5.31667 5.31699 2.33334 9.00033 2.33334C12.6837 2.33334 15.667 5.31667 15.667 9C15.667 12.6833 12.6837 15.6667 9.00033 15.6667Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export interface LogoProps {
  settings: SvgIconProps;
  imgSrc?: string;
  imgAlt?: string;
}

export type LogoIconProps = LogoProps & {
  settings: LogoProps;
};
