import React, { Component, RefObject } from "react";

interface DoubleScrollbarProps {
  children: React.ReactNode;
}

interface DoubleScrollbarState {
  width: string | number;
}

type OverflowY = "auto" | "visible" | "hidden" | "scroll" | "inherit" | "initial" | "revert" | "unset";

class DoubleScrollbar extends Component<DoubleScrollbarProps, DoubleScrollbarState> {
  private outerDivRef: RefObject<HTMLDivElement>;
  private innerDivRef: RefObject<HTMLDivElement>;
  private childrenWrapperRef: RefObject<HTMLDivElement>;
  private boundCalculateWidth: () => void;

  constructor(props: DoubleScrollbarProps) {
    super(props);
    this.state = {
      width: "auto",
    };

    this.outerDivRef = React.createRef();
    this.innerDivRef = React.createRef();
    this.childrenWrapperRef = React.createRef();
    this.boundCalculateWidth = this.calculateWidth.bind(this);
  }

  componentDidMount() {
    const outerDiv = this.outerDivRef.current;
    const childWrapper = this.childrenWrapperRef.current;

    // Set initial width
    this.calculateWidth();

    // Update width when window size changes
    window.addEventListener("resize", this.boundCalculateWidth);

    // assoc the scrolls
    if (outerDiv && childWrapper) {
      outerDiv.addEventListener("scroll", () => {
        childWrapper.scrollLeft = outerDiv.scrollLeft;
      });

      childWrapper.addEventListener("scroll", () => {
        outerDiv.scrollLeft = childWrapper.scrollLeft;
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.boundCalculateWidth);
  }

  componentDidUpdate() {
    this.calculateWidth();
  }

  calculateWidth() {
    let width = this.getChildWrapperWidth();

    if (width == null) {
      width = "auto";
    }

    // Set the width of the inner div to the first child's
    if (width !== this.state.width) {
      this.setState({
        width: width,
      });
    }
  }

  getChildWrapperWidth() {
    let width = null;
    if (this.childrenWrapperRef.current && this.childrenWrapperRef.current.scrollWidth) {
      width = this.childrenWrapperRef.current.scrollWidth + "px";
    }
    return width;
  }

  render() {
    const outerDivStyle: React.CSSProperties = { overflowX: "auto", overflowY: "hidden" };
    const innerDivStyle: React.CSSProperties = { paddingTop: "1px", width: this.state.width };
    const childDivStyle: React.CSSProperties = { overflow: "auto", overflowY: "hidden" as OverflowY };

    return (
      <div>
        <div ref={this.outerDivRef} style={outerDivStyle}>
          <div ref={this.innerDivRef} style={innerDivStyle}>&nbsp;</div>
        </div>
        <div ref={this.childrenWrapperRef} style={childDivStyle}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default DoubleScrollbar;
