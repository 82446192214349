import axios, { AxiosError } from "axios";
import { useState, useEffect, useMemo } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import { sourceTrackingData } from "../../interfaces/sourceTrackingData";

const WrapperBase = styled.div`
  border-bottom: 3px solid var(--pm-green);
  margin-bottom: 3rem;
  padding-bottom: 3rem;
`;

const MessageWrapper = styled.p`
  background-color: var(--bg-medium);
  padding: 0.5rem 1rem;
`;

const Wrapper = styled(WrapperBase)``;

export const AdminTools = () => {
  const [message, setMessage] = useState("");
  const [data, setData] = useState<sourceTrackingData[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isUpdatePaymentStatusLoading, setIsUpdatePaymentStatusLoading] = useState(false);
  const [isClearTestDataLoading, setIsClearTestDataLoading] = useState(false);
  const [bootcampsIds, setBootcampsIds] = useState<
    {
      id: string;
      title: string;
    }[]
  >([]);
  const [cacheProgress, setCacheProgress] = useState<number>(0);
  const [updatePaymentStatusProgress, setUpdatePaymentStatusProgress] = useState<number>(0);

  const sourceTrackingData = useMemo(() => {
    return data?.map((obj) => {
      const matchingObject = bootcampsIds.find(
        (bootcamp) => obj.BootcampId === bootcamp.id
      );

      const commonProperties = {
        ID: obj.ID,
        BootcampId: obj.BootcampId,
        BootcampName: matchingObject ? matchingObject.title : "",
        PartyId: obj.PartyId,
        PMO_ID: obj.PMO_ID,
        Field_Name: obj.Field_Name,
        Field_Value: obj.Field_Value,
        Event_Name: obj.Event_Name,
        CourseId: obj.CourseId,
        Created_Date: obj.Created_Date,
      };

      return commonProperties;
    });
  }, [data, bootcampsIds]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/exportsourcetrackingdata`;

      try {
        const response = await axios.get(url, {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          setData(response.data);
          setIsDataLoaded(true);
        } else {
          setMessage(response.statusText);
        }
      } catch (error) {
        const err = error as AxiosError;
        setMessage(
          err.response?.status === 404
            ? "Unable to backfill promo codes"
            : err.message
        );
      }
    };

    fetchData();
  }, []);

  // Format date to YYYYMMDD
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // add 0 if month is less than 10
  const day = date.getDate().toString().padStart(2, "0"); // add 0 if day is less than 10
  const formattedDate = `${year}${month}${day}`;
  const filename = `BCSourceTrackingData_${formattedDate}.csv`;

  const handleClearSurveysTestData = () => {
    setIsClearTestDataLoading(true);
    const url = `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/removesurveystestdata`;

    axios({
      method: "Put",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage(response.data);
          setIsClearTestDataLoading(false);
        } else {
          setMessage(response.statusText);
        }
      })
      .catch((error) => {
        const err = error as AxiosError;
        setMessage(err.message);
      })
      .finally(() => {
        setIsClearTestDataLoading(false);
      });
  };

  const handleUpdatePaymentStatus = () => {
    setIsUpdatePaymentStatusLoading(true);
    const url = `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/updatepaymentstatus`;

    axios({
      method: "Get",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage(response.data);
          monitorUpdatePaymentStatusProgress();
        } else {
          setMessage(response.data);
        }
      })
      .catch((error) => {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          setMessage("Unable to update payment status");
        } else {
          setMessage(err.message);
        }
      });
  };

  const monitorUpdatePaymentStatusProgress = () => {
    const url = `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/gettransactionsyncprogress`;

    const interval = setInterval(() => {
      axios.get(url)
        .then((response) => {
          if (response.status === 200) {
            setUpdatePaymentStatusProgress(response.data);
            if (response.data >= 100) {
              clearInterval(interval);
              setIsUpdatePaymentStatusLoading(false);
            }
          } else {
            setMessage(response.statusText);
          }
        })
        .catch((error) => {
          const err = error as AxiosError;
          setMessage(err.message);
          clearInterval(interval);
          setIsUpdatePaymentStatusLoading(false);
        });
    }, 5000);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getallbootcamps`
      )
      .then((response) => {
        const bootcampsData = response.data;
        const bootcamps = Object.keys(bootcampsData).map((key) => ({
          id: key,
          title: bootcampsData[key],
        }));
        setBootcampsIds(bootcamps);
      })
      .catch((error) => {
        console.log("Error fetching bootcamps:", error);
      });
  }, []);

  useEffect(() => {
    const fetchCacheProgress = () => {
      fetch(
        `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getcacheprogress`
      )
        .then((response) => response.json())
        .then((data) => {
          setCacheProgress(Math.round(data));
        })
        .catch((error) => {
          console.log("Error fetching cache progress:", error);
        });
    };

    fetchCacheProgress();

    const interval = setInterval(fetchCacheProgress, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="col-12 px-3">
      <h1>Admin Tools</h1>
      {message && <MessageWrapper>{message}</MessageWrapper>}
      <Wrapper>
        <div className="row mt-4">
          {!isDataLoaded && (
            <div className="col-3">
              <h3>Export Source Tracking Data</h3>
              <button
                className="cta cta--small cta--navy"
                style={{ minWidth: "150px" }}
                disabled={true}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Loading...</span>
              </button>
            </div>
          )}
          {isDataLoaded && (
            <div className="col-3">
              <h3>Export Source Tracking Data</h3>
              <CSVLink
                data={sourceTrackingData}
                filename={filename}
                className="cta cta--small cta--navy"
              >
                <span>Export Data</span>
              </CSVLink>
            </div>
          )}
        </div>
      </Wrapper>
      <Wrapper>
        <div className="row">
          <div className="col-3">
            <h3>Clear Surveys Test Data</h3>
            <button
              style={{ minWidth: "146px" }}
              onClick={handleClearSurveysTestData}
              className="cta cta--small cta--navy"
            >
              {isClearTestDataLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <span>Clear Data</span>
              )}
            </button>
          </div>
        </div>
      </Wrapper>
      <Wrapper>
        <div className="row">
          <div className="col-3">
            <h3> Synchronize Transaction Data</h3>
            <button
              style={{ minWidth: "146px" }}
              onClick={handleUpdatePaymentStatus}
              className="cta cta--small cta--navy"
            >
              {isUpdatePaymentStatusLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <span>Synchronize Data</span>
              )}
            </button>
            {isUpdatePaymentStatusLoading && (
              <p>Update Progress: {updatePaymentStatusProgress.toFixed(0)}%</p>
            )}
          </div>
        </div>
      </Wrapper>
      <Wrapper>
        <div className="row">
          <div className="col-3">
            <h3>Member Cache</h3>
            <p>Cache Progress: {cacheProgress}%</p>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default AdminTools;