import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import styled from "styled-components";
import { InputRowWrapper } from "../InputRow";
import BootcampSelector from "../BootcampSelector";
import { Loading } from "../Loading";
import { QuestionResult } from "../../interfaces/memberSummary";
import SubmittedQuestions from "../SubmittedQuestions";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 5rem;
`;

export const Questions: React.FC = () => {
  const { pmoId } = useParams();
  const [data, setData] = useState<QuestionResult[] | null>(null);
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBootcamp, setSelectedBootcamp] = useState<string>("");

  const handleBootcampChange = (bootcampId: string) => {
    setSelectedBootcamp(bootcampId);
  };

  const handleLoadData = () => {
    const url = `${process.env.REACT_APP_NOT_SECRET_SERVICE_URL}/webapi/adminreporting/getquestions?bootcampId=${selectedBootcamp}&pmoId=${pmoId ? `${pmoId}` : ""}`;
    setLoading(true);

    axios({
      method: "Get",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage("");
          setData(response.data.Items);
        } else {
          setMessage(response.statusText);
        }
      })
      .catch((error) => {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          setMessage("Questions not found");
          setData(null);
        } else {
          setMessage(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const headers = [
    { label: "BootcampId", key: "BootcampId" },
    { label: "CourseId", key: "CourseId" },
    { label: "CourseTitle", key: "CourseTitle" },
    { label: "PMOId", key: "PMOId" },
    { label: "FirstName", key: "FirstName" },
    { label: "LastName", key: "LastName" },
    { label: "EmailAddress", key: "EmailAddress" },
    { label: "SubmissionDate", key: "SubmissionDate" },
    { label: "QuestionText", key: "QuestionText" },
  ];

  const csvData = data
    ? data.map((question) => ({
        BootcampId: question.SubmitQuestionData.BootcampId,
        CourseId: question.SubmitQuestionData.CourseId,
        CourseTitle: question.SubmitQuestionData.CourseTitle,
        PMOId: question.PMOId,
        FirstName: question.FirstName,
        LastName: question.LastName,
        EmailAddress: question.EmailAddress,
        SubmissionDate: question.SubmitQuestionData.SubmissionDate,
        QuestionText: question.SubmitQuestionData.QuestionText,
      }))
    : [];

  const formattedDate = new Date().toISOString().replace(/[-:.TZ]/g, "");
  const filename = `Question-Report-${formattedDate}.csv`;

  useEffect(() => {
    if (pmoId) {
      handleLoadData();
    }
  }, [pmoId]);

  return (
    <>
      <h1>Questions</h1>
      {message && <p>{message}</p>}
      <InputRowWrapper>
        Bootcamp:
        <BootcampSelector onSelectBootcamp={handleBootcampChange} />
        <button
          onClick={handleLoadData}
          className="cta cta--small cta--navy"
        >
          <span>Load Data</span>
        </button>
        {data && data.length > 0 && (
          <CSVLink
            data={csvData}
            headers={headers}
            filename={filename}
            className="cta cta--small cta--navy"
            style={{ borderRadius: "4px", margin: "0 1rem" }}
          >
            <span>Export CSV</span>
          </CSVLink>
        )}
      </InputRowWrapper>
      {loading ? (
        <LoadingWrapper>
          <Loading />
          <br />
          <p>Loading...</p>
        </LoadingWrapper>
      ) : (
        data && <SubmittedQuestions questionResults={data} />
      )}
    </>
  );
};