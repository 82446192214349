import { GraphQLClient } from "graphql-request";

export const FetchData = async <T>(query: string):  Promise<T> => {
  try {
    const endpoint =
      process.env.REACT_APP_NOT_SECRET_CM_URL + "/sitecore/api/graph/edge/";
    const graphQLClient = new GraphQLClient(endpoint);
    graphQLClient.setHeader(
      "sc_apikey",
      process.env.REACT_APP_SITECORE_API_KEY as string
    );
  return  await graphQLClient.request(query as string)

  } catch (error) {
    console.error("Error fetching survey data:", error);
    throw error;
  }
};
